
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
//import { ViewCell } from 'ng2-smart-table';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'app-table-checkbox',
  template: `<button type="button" mat-flat-button *ngIf="!value && enabled" (click)="click(true)" >Conferma</button>
              <button type="button" mat-flat-button *ngIf="!value && enabled" (click)="click(false)" >Declina</button> 
              
              <span *ngIf="value || !enabled">{{value|date:'dd/MM/yyyy HH:mm'}}</span>
              <span *ngIf="value=='rejected' || !enabled">RIFIUTATO</span>`
})
export class TableConfirmButton implements  OnInit {

  @Input() value;
  @Input() rowData: any;
  @Output() onClick: EventEmitter<any> = new EventEmitter()
  userCompetence: boolean;
  enabled

  constructor(private userService: UserService) {

    this.enabled = this.userService.getRole() == 'ADMIN' || this.userService.getRole() == 'GESTORE'

  }

  ngOnInit() {
  }

  click(b) {
    this.onClick.emit({rowData: this.rowData, value: b});
  }

}