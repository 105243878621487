<div style="display: block; margin:auto; width:95%">
  <div [id]="'searchBoxContainer_' + id" class="searchBoxContainer">

    <mat-form-field>
      <input matInput type='text' [id]="'searchBox_' + id" style="width:100%; margin-bottom:8px;"
        placeholder="Cerca indirizzo..." [readonly]="disabled" autocomplete="off" [(ngModel)]="locationSearch"
        [autocomplete]="'off'" />
    </mat-form-field>

  </div>

  <div [id]="'map_' + id" style='width: 100%; margin:0px;'
    [ngStyle]="{'height' : height, 'display': showMap ? 'block' : 'none' }">
  </div>

</div>