import { Pipe, PipeTransform } from '@angular/core';
import { IUtente } from '../models/utente.interface';

@Pipe({
  name: 'userFilterByParent',
})
export class UserFilterByParentPipe implements PipeTransform {

  transform(items: any[], args: string): any {
    items = items.filter(item => {
      return args[0] == '' || item.genitore == args[0];
    });

    items = items.sort((a, b) => {

      if (a.province.includes(args[1]) && !b.province.includes(args[1])) return -1
      if (b.province.includes(args[1]) && !a.province.includes(args[1])) return 1

      return `${a.nome} ${a.cognome}` > `${b.nome} ${b.cognome}` ? 1 : -1

    })

    return items
  }

  sortIspettori() {

  }

}
