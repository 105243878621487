//import { ReadMode } from 'ngx-file-helpers';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DecimalPipe } from '@angular/common'
import { IResult, IPerizia } from '../../models/perizia.interface';
import { OsmService } from '../../services/osm.service';
import { FilterByFileTypePipe } from 'app/pipes/filter-by-file-type.pipe';
import { IFile } from 'app/models/incarico.interface';
import { MysqlService } from 'app/services/mysql.service';

//import { create360Viewer,canvasFit  } from '360-image-viewer'

declare let L;

@Component({
	selector: 'app-gallery-result',
	templateUrl: './gallery-result.component.html',
	styleUrls: ['./gallery-result.component.scss']
})

export class GalleryResultComponent implements OnInit {

	public readMode = 2;

	showFotoVetrina = false;
	showAltriElementi = false;

	@Input() showPP = false;
	@Input() hideUploader = false;

	@Input() orderMode = false;



	@Output() onOpenViewer: EventEmitter<any> = new EventEmitter();

	openViewer(event) {
		console.log('openViewer result')
		this.onOpenViewer.emit(event)
	}


	map: any
	layers = L.layerGroup();

	blurOptions = {
		scaleMask: 80,
		blurMask: 2,
		blurOpacity: 90,
		blurQuantity: 2,
	}

	//blur = true;

	@Output() onSelected = new EventEmitter<any>();
	@Output() onAdd = new EventEmitter<void>();
	@Output() onDelete = new EventEmitter<void>();

	source: IPerizia
	img_360: string;

	@Input() //files: any[];
	set setSource(source) {

		setTimeout(() => {

			console.log('prev value: ', this.source);
			console.log('got name: ', source);

			if (source && source.allegati) {

				console.log('allegati sort 1 ', source.allegati)
				source.allegati = source.allegati.sort((a, b) => {

					if (a.fileName.startsWith('F') && b.fileName.startsWith('F')) {


						let n1 = (a.fileName as string).substring(1)
						let n2 = (b.fileName as string).substring(1)
						if (n1.endsWith('.JPG') && n2.endsWith('.JPG')) {
							n1 = n1.substring(0, n1.length - 4)
							n2 = n2.substring(0, n2.length - 4)
						}
						let nn1 = +n1
						let nn2 = +n2
						return nn1 > nn2 ? 1 : -1

					} else
						return a.fileName > b.fileName ? 1 : -1

				}).filter(e => !this.disabled || !e.hidden)

				console.log('allegati sort 2 ', source.allegati)


			}

			this.source = source;

			//console.clear()
			console.log(source)

			this.initTab()


			console.log('inittab: ' + this.tab);
		}, 100);

	}

	@Input() //files: any[];
	set set360(source) {
		console.log('360 prev value: ', this.source);
		console.log('360 got name: ', source);
		this.img_360 = source;
	}

	@Input() blur: boolean;

	role;
	@Input() set setRole(role) {
		this.role = role;
		this.initTab();
	}

	@Input() accepted: string = '';
	@Input() disabled: boolean = false;

	tab;


	constructor(private decimalPipe: DecimalPipe,
		private filterByFileTypePipe: FilterByFileTypePipe,
		private osm: OsmService,
		private db: MysqlService) {


	}

	ngOnInit() {

		if (!this.source.result && this.source.allegati && this.source.allegati.length > 0) this.tab = 'altri_file';

	}

	initTab() {
		setTimeout(() => {
			if (this.source && this.source.result && this.source.result.fotoVetrina && this.source.result.fotoVetrina.length > 0) {
				this.source.result.fotoVetrina.forEach(x => {
					if (x.img) this.showFotoVetrina = true;
				})
			}

			if (this.source && this.source.result && this.source.result.altriElementi && this.source.result.altriElementi.length > 0) {
				this.source.result.altriElementi.forEach(x => {
					if (x.img) this.showAltriElementi = true;
				})
			}


			if (this.showFotoVetrina && this.role == 'CLIENTE') {
				this.tab = 'slideshow';
			} else if (this.showFotoVetrina) {
				this.tab = 'foto_vetrina';
			}

			else if (this.showAltriElementi) {
				this.tab = 'altri_elementi';
			}

			
			else if (!this.source.result && this.source.allegati && this.source.allegati.length > 0) this.tab = 'altri_file';


			else if (this.img_360) {

				this.tab = '360';

			} else if (this.source && this.source.result && this.source.result.damages && this.source.result.damages.length > 0) {

				this.tab = 'danni'

			} else if (this.source && this.source.result && this.source.result.attachments && this.source.result.attachments.length > 0) {

				this.tab = 'camera'

			} else if (this.source && this.source.result && this.source.result.geo) {

				this.tab = 'geo'

			}

		}, 1000)

	}


	drag(ev, e) {
		console.log(ev, e)

		ev.dataTransfer.setData('text', JSON.stringify({
			data: e,
			i: this.source.allegati.findIndex(ex => ex === e)
		}));
	}

	allowDrop(ev) {
		ev.preventDefault();
	}

	drop(ev, i) {

		const data = JSON.parse(ev.dataTransfer.getData('text'))

		console.log(data, i)

		const e = this.source.allegati.splice(data.i, 1)
		const end = this.source.allegati.splice(i)


		this.source.allegati = this.source.allegati.concat(e, end)

	}

	isTiresDataPresent() {

		if (!this.source.result.tiresData) return false

		for (const tire of this.source.result.tiresData.tires) {

			if (tire.brand && tire.brand != '') return true
			if (tire.type && tire.type != '') return true

			if (tire.d && tire.d != 0) return true
			if (tire.h && tire.h != 0) return true
			if (tire.l && tire.l != 0) return true
			if (tire.mm && tire.mm != 0) return true


			if (tire.img) return true

		}

		return false

	}

	getGlassName(i) {

		i = i % 11

		while (i < 0) i += 11

		switch (i) {
			case 0: return 'Parabrezza'
			case 1: return 'Deflettore anteriore lato guida'
			case 2: return 'Finestrino anteriore lato guida'
			case 3: return 'Finestrino posteriore lato guida'
			case 4: return 'Deflettore posteriore lato guida'
			case 5: return 'Lunotto'
			case 6: return 'Deflettore posteriore lato passeggero'
			case 7: return 'Finestrino posteriore lato passeggero'
			case 8: return 'Finestrino anteriore lato passeggero'
			case 9: return 'Deflettore anteriore lato passeggero'
			case 10: return 'Tetto'
		}

	}

	loadMapTab() {
		this.tab = 'geo';

		setTimeout(() => {
			this.loadMap();
		}, 100);

	}

	loadMap() {

		this.map = null;

		if (!this.map) {
			this.map = L.map('map');
			this.map.addLayer(this.layers);
		};


		this.map.setView([this.source.result.geo.latitude, this.source.result.geo.longitude], 13);

		L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
		}).addTo(this.map);

		L.marker([this.source.result.geo.latitude, this.source.result.geo.longitude]).addTo(this.map)
			.bindPopup("Geolocalizzazione effettuata qui")
			.openPopup();

	}


	changeBlur(e) {

		console.log('change blur: ' + this.blur)
		console.log(e)

		this.blur = e.checked;

		for (let i = 0; i < this.source.result.fotoVetrina.length; i++) {
			this.source.result.fotoVetrina[i].url = setBlurredUrl(this.blur, this.source.result.fotoVetrina[i].url);
		}

		console.log(this.source.result.fotoVetrina)
	}

	refreshBlur() {

		this.changeBlur({ checked: false })

		this.db.perizia_blur(this.source.id, this.blurOptions).then(res => {
			console.log(res);
			this.changeBlur({ checked: true })
		}).catch(err => console.error(err))

	}


	/*
	//////////////////////////// FILES ////////////////////////////
	getBase64(file: File) {
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = res => {
		
			let tmp: IFile = {
				fileName: file.name,
				size: file.size,
				fileContent: '' + reader.result,
				creationDate: new Date()
			}
		
			if (!this.source) this.source = [];
			this.source.push(tmp);
		
			this.onAdd.emit();
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	}
		
		
		uploadFile(event) {
			console.log(event)
		
			for (let i = 0; i < event.target.files.length; i++) {
				const file: File = event.target.files[i];
				this.getBase64(file);
			}
		
		
		}
		
		dropFile(event) {
		
			let tmp: IFile = {
				fileName: event.name,
				fileContent: event.content,
				size: event.size,
				creationDate: new Date()
			}
		
			if (!this.source) this.source = [];
			this.source.push(tmp);
			this.onAdd.emit();
		}
	*/
	uploadFile(e) {

	}

	isImage(f: IFile) {

		let ext = f.fileName.slice(-3).toLocaleLowerCase();

		switch (ext) {
			case 'jpg':
			case 'png':
			case 'gif':
			case 'peg':
				return f.fileContent != '' ? f.fileContent : f.url;

			case 'pdf':
			case 'adz':
				return 'assets/img/pdf.png'

			default:
				return 'assets/img/attachment.png'

		}
	}

	humanize(val: number) {

		if (val < 1024) {
			return this.decimalPipe.transform(val, '.1-1') + 'B';
		}

		val = val / 1024;
		if (val < 1024) {
			return this.decimalPipe.transform(val, '.1-1') + 'KB';
		}

		val = val / 1024;
		if (val < 1024) {
			return this.decimalPipe.transform(val, '.1-1') + 'MB';
		}

		val = val / 1024;
		if (val < 1024) {
			return this.decimalPipe.transform(val, '.1-1') + 'GB';
		}

		val = val / 1024;
		return this.decimalPipe.transform(val, '.1-1') + 'TB';

	}

	tab_hover = '';
	mouseEnter(e) {
		this.tab_hover = e.srcElement.value;
	}
	mouseLeave(e) {
		this.tab_hover = '';
	}

	openFileEvent(e) {

		//console.clear()
		console.log(e)

		this.onSelected.emit(e);
	}

	openFile(source, i: number, double: boolean = false) {

		console.log(source)

		let tmp = this.filterByFileTypePipe.transform(source, ['img', 'pdf', 'oth', this.showPP ? 'pp' : 'xx'])

		let data = { source: tmp, i: i, double: double };
		console.log(data);
		this.onSelected.emit(data);
	}


	openFileDamageInt(source, i: number, double: boolean = false) {
		//double = true
		console.log(source)
		let defSource = []
		for (const iterator of source) {
			//defSource.push(iterator.img2)
			defSource.push(iterator.img1)
		}
		let tmp = this.filterByFileTypePipe.transform(defSource, ['img', 'pdf', 'oth', this.showPP ? 'pp' : 'xx'])
		let data = { source: tmp, i: i, double: double };
		console.log(data);
		this.onSelected.emit(data);
	}

	openGlass(d) {

		let tmp = { source: [d.img1, d.img2], i: 0, double: true, descr: [this.getGlassName(d.id)] }

		console.log(tmp)

		this.onSelected.emit(tmp)

	}

	viewer: any;




	deleteFotoVetrina(i) {
		this.source.result.fotoVetrina[i].img = null;
		this.source.result.fotoVetrina[i].url = '';
		this.source.result.fotoVetrina[i].thumb = null;

		this.onDelete.emit()

	}

	deleteAttachments(i) {
		this.source.result.attachments[i].img = null;
		this.source.result.attachments[i].url = '';
		this.source.result.attachments[i].thumb = null;

		this.onDelete.emit()

	}


	deleteAltriElementi(i) {
		this.source.result.altriElementi[i].img = null;
		this.source.result.altriElementi[i].url = '';
		this.source.result.altriElementi[i].thumb = null;

		this.onDelete.emit()

	}


	deleteDocumenti(i) {
		this.source.result.documenti.splice(i, 1);
		this.onDelete.emit()

	}

	renameAllegati(e: IFile) {

		let exts = e.fileName.split('.')

		let ext = exts.pop()
		let filename = exts.join('.')


		let x = prompt("Inserisci un nuovo nome: ", filename)
		if (!x || x == '') return

		e.fileName = x + '.' + ext


		//nome fuorviante, in realtà scatena l'elvento salvataggio nel componente parent
		this.onDelete.emit();

	}

	deleteAllegati(e: IFile) {

		//console.clear();

		if (confirm('Eliminare il file selezionato?'))
			this.source.allegati.forEach((el, i) => {

				console.log(el)

				if (el.fileName == e.fileName) {

					console.log('found')


					console.log(this.source.allegati.length)

					this.source.allegati.splice(i, 1)

					console.log(this.source.allegati.length)

					this.onDelete.emit();

					return;
				}
			})



	}

	toggleVisibilityAllegati(e: IFile) {



		e.hidden = !e.hidden


		//nome fuorviante, in realtà scatena l'elvento salvataggio nel componente parent
		this.onDelete.emit();

	}



	openVideo(v) {
		console.log(v)
		this.db.opentok.archive_get(v).then(res => {
			console.log(res)
			window.open(res.url, '_blank')
		})
	}



}


export const setBlurredUrl = function (blur, x) {

	console.log(x)

	if (x.split('&ts=').length > 1) x = x.split('&ts=')[0];
	x = x.replace('&blur=1', '').replace('&blur=0', '');
	let b = '0';
	blur ? b = '1' : b = '0';
	x += '&blur=' + b
	x += '&ts=' + (new Date().getMilliseconds())
	console.log(x)
	return x;
}