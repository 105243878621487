import { Component, OnInit, ViewEncapsulation, } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MysqlService } from 'app/services/mysql.service';
import { IFir } from 'app/models/fir.interface';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { UserService } from 'app/services/user.service';
import { HttpEventType } from '@angular/common/http';



@Component({
  selector: 'app-dialog-fir',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],

  encapsulation: ViewEncapsulation.None,


})

export class FirDialogComponent implements OnInit {

  id: number;
  item: IFir;

  editMode: boolean;

  loading = false;
  loading_percentage;

  sessionId: string;
  token: string;

  form: UntypedFormGroup;

  role: string = '';
  client;

  isWritable: boolean;

  types = [
    "Assistenza per problematiche tecniche in fase di installazione",
    "Chiarimenti su applicazioni della garanzia",
    "Supporto tecnico per veriﬁca tempari e ricambi",
    "Supporto per risposta a cliente precontenzioso",
    'Altro'
  ]
  productCategories = [
    "Motore", "Testa Cilindro", "Turbocompressore", "Cambio", "Alberi trasmissione", "Altro"
  ]


  states = [];
  productCategoryChange(cat) {

    console.log(cat)

    let states = [
      { cat: 'Motore', state: 'Usato' },
      { cat: 'Motore', state: 'Ricostruito' },
      { cat: 'Motore', state: 'Rigenerato' },
      { cat: 'Motore', state: 'Nuovo' },
      { cat: 'Testa Cilindro', state: 'Rigenerato' },
      { cat: 'Testa Cilindro', state: 'Nuovo' },
      { cat: 'Turbocompressore', state: 'Rigenerato' },
      { cat: 'Turbocompressore', state: 'Originale nuovo' },
      { cat: 'Turbocompressore', state: 'Aftermarket nuovo' },
      { cat: 'Cambio', state: 'Rigenerato' },
      { cat: 'Cambio', state: 'Nuovo' },
      { cat: 'Alberi trasmissione', state: 'Nuovo' },
      { cat: 'Altro', state: 'Usato' },
      { cat: 'Altro', state: 'Ricostruito' },
      { cat: 'Altro', state: 'Rigenerato' },
      { cat: 'Altro', state: 'Nuovo' },
      { cat: 'Altro', state: 'Originale nuovo' },
      { cat: 'Altro', state: 'Aftermarket nuovo' },
    ];

    //this.states = []

    this.states = states.filter(x => { return x.cat == cat }).map(v => { return v.state });
    //console.log(tmp)
    //tmp.forEach(v => this.states.push(v.state) )

    console.log(this.states)
  }

  powerTypes = [
    'Benzina',
    'Diesel',
    'Benzina/metano',
    'Benzina/GPL',
    'Monofuel Metano',
    'Ibrida',
    'Altro',

  ]
  applications = [
    'Auto',
    'Veicolo commerciale',
    'Industriale stradale',
    'Agricolo',
    'Marine',
    'Industriale stazionario',
    'Indistriale ferroviario',
    'Altro',
  ]

  clientTypes = [
    'Autoriparatore',
    'Azienda automotive',
    'Privato consumatore'
  ]



  constructor(
    private route: ActivatedRoute,
    private db: MysqlService,
    public dialog: MatDialog,
    private location: Location,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,

  ) {


    this.role = userService.getCurrentUser().role;
    this.client = userService.getCurrentUser().client;

    this.form = this.formBuilder.group({

      type: ['', [Validators.required]],
      productCategory: ['', [Validators.required]],
      productState: ['', [Validators.required]],
      powerType: ['', [Validators.required]],
      application: ['', [Validators.required]],
      refPerson: ['', [Validators.required]],
      refPhone: ['', [Validators.required]],
      note: ['', [Validators.required]],

      status: ['', [Validators.required]],

      response: ['',],

      clientExt: ['', [Validators.required]],
      clientType: ['', [Validators.required]],

      lead1: ['',],
      lead2: ['',],
      lead3: ['',],
    });


  }

  isUser(): boolean {
    return this.role == 'CLIENTE';
  }

  isAdmin(): boolean {
    return this.role == 'ADMIN' || this.role == 'ISPETTORE';
  }

  isDisabled(): boolean {
    return this.editMode && this.item.status != 'RICHIESTA INTEGRAZIONE';
  }

  ngOnInit() {
    this.id = +this.route.snapshot.paramMap.get('id');
    this.loadData();
  }




  loadData() {

    this.item = this.getEmptyFir();

    this.editMode = this.id > 0;

    if (this.id > 0)
      this.db.fir.get(this.id).then(res => {

        console.log(res)

        console.log('data', res)
        this.item = res.item;
        this.loadAll();

      }).catch(err => console.error(err));

    else
      this.loadAll();

  }

  loadAll() {

    if (this.isUser() && !this.editMode) { this.isWritable = true; console.log(1) }
    else if (this.isUser() && this.item.status == 'RICHIESTA INTEGRAZIONE') { this.isWritable = true; console.log(2) }
    else if (this.isAdmin()) { this.isWritable = true; console.log(3) }
    else { this.isWritable = false; console.log(4) }


    this.productCategoryChange({ value: this.item.productCategory })

    for (let i = 0; i < this.item.attachments.length; i++) {
      this.item.attachments[i].url = this.db.fir.get_attachment_url(this.item.id, this.item.attachments[i], 'allegati');
    }

  }


  download(updateStatus: boolean) {

    if (updateStatus) {
      this.item.status = 'DOCUMENTAZIONE SCARICATA';
      this.save(false);
    }


    window.open(this.db.fir.print_pdf(this.item.id), '_blank');
  }

  getEmptyFir() {
    let tmp: IFir = {
      dta: new Date(),
      type: '',
      productCategory: '',
      productState: '',
      powerType: '',
      application: '',
      refPerson: '',
      refPhone: '',
      attachments: [],
      status: 'IN LAVORAZIONE',

      note: '',
      lead1: -1,
      lead2: -1,
      lead3: -1,

      response: '',

      clientExt: '',
      clientType: ''
    };

    return tmp;

  }

  save(closeWin: boolean = true) {

    if (this.loading) return;

    if (this.form.invalid) {
      console.warn('form invalid')
      return;
    }



    this.loading = true;


    if (this.isUser() && this.item.status == 'RICHIESTA INTEGRAZIONE') this.item.status = 'IN LAVORAZIONE';


    this.db.fir.upsert(this.item).subscribe((event: any) => {


      // progress
      if (event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress) {
        const percentage = 100 / event.total * event.loaded;

        this.loading_percentage = percentage;


        console.log(percentage);
      }

      // finished
      if (event.type === HttpEventType.Response) {
        this.loading = false;
        if (event.body.status != 'OK') {
          console.error(event.body)
          return;
        }

        if (closeWin) {
          this.close();
          return;
        }

        this.item = event.body.item;
        this.editMode = true;

        this.loadData();
      }



    })

  }

  close() {
    this.location.back();
  }


  docviewer = false;
  docviewer_i = 0;
  docviewer_source = [];
  docviewer_position = 'center';
  docviewer_filter = '';

  openViewer(e, source) {
    console.log(e)
    this.docviewer_i = 0;
    this.docviewer_source = source

    this.docviewer_i = e.i;
    this.docviewer_filter = e.filter;
    this.docviewer = true;

  }
  closeDocviewer() {
    this.docviewer = false;
  }

  isMSIE() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      let r = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      console.log(r);
      return r;
    }
  }

}