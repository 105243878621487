<div class="main-content" style=" height: calc(100vh - 20px)!important; padding-top:0; margin-bottom:0; 
  padding: 5%">
  <div class="card" style="padding:1em">

    <div class="card-header card-header-info">
      <h4 class="card-title">

        IMPORTAZIONE VDR

        <div style="float:right"><a (click)="close()"><i class="material-icons">close</i></a>
        </div>
      </h4>
    </div><br>

    <button type="button" mat-raised-button class="btn btn-info" (click)="fileVDR.click();">CARICA VDR</button>
    <input type="file" #fileVDR (change)="uploadVDR($event)" style="display: none;" id="file" accept="image/*">


    <div class="row">


      <div class="col-12">

        <ion-spinner *ngIf="loading"></ion-spinner>

        <div *ngIf="id || targa" class="rag-green">
          <h2>{{id}}<br>{{targa}}
            <i *ngIf="!loadingManualSearch" class="material-icons" (click)="setManual()"> edit </i>
          </h2>

          <div *ngIf="p">
            {{p.marca}} {{p.modello}}<br>
            {{p.telaio}}
            <br><br>

            <ion-spinner *ngIf="saving"></ion-spinner>
            <button *ngIf="!error && !saving" class="btn btn-success" type="button" mat-button
              (click)="saveFile()">salva</button>
          </div>

        </div>
        <div *ngIf="error" class="rag-red">
          <h2>{{error}}</h2>
        </div>


        <div *ngIf="showManualSearch">
          <mat-form-field style="margin:0!important">
            <input type="text" style="height:200%" matInput placeholder="Digita la targa"
              (keydown.enter)="cercaPeriziaManuale()" [(ngModel)]="targaManuale">


            <button class="btn btn-success" type="button" matSuffix mat-icon-button (click)="cercaPeriziaManuale()">
              <i *ngIf="!loadingManualSearch" class="material-icons"> search </i>
              <mat-spinner *ngIf="loadingManualSearch" diameter="18"></mat-spinner>
            </button>

          </mat-form-field>
        </div>

      </div>

      <div class="col">
        <img [src]="img" style="width:100%; height: auto; object-fit: cover; object-position: top center;">
      </div>
    </div>

  </div>




  <div class="spin-cnt" *ngIf="loading">
    <mat-spinner color="white"></mat-spinner>
  </div> 