<div class="main-content" style="height: calc(100vh - 20px);; padding-top:0; margin-bottom:0">
  <div class="row" style="height: 100%;  margin-bottom:0">
    <div class="col-md-12" style="height: 100%; margin-bottom:0">


      <form [formGroup]="form" (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" style="height: 100%; ">
        <div class="card">
          <div class="card-header card-header-info">
            <h4 class="card-title">
              <div class="margin-title-header">
                <div style="margin-top: 0.5rem; margin-right: 1rem;">Perizia - ID: {{item.id ? item.id : 'NUOVO'}}</div>
                <div *ngIf="item.tipo == 'unipolrental' && +item.ContrattoQuadroSifa > 0" class="cornice-logo-sifa">
                  <img style="height:40px" src="/assets/images/logo-sifa.png" />
                </div>
                <div *ngIf="item.tipo == 'unipolrental' && +item.ContrattoQuadroSifa == 0"
                  class="cornice-logo-unipolrental">
                  <img style="height:40px" src="/assets/images/logo-unipolrental.png" />
                </div>

              </div>

              <div style="float:right">
                <!--
                <button style="margin-right: 10px;" mat-raised-button type="button" (click)="allegaVDRTest()"
                  *ngIf="editMode" class="btn btn-success" >
                  VDR Test
                </button>
                -->
                <button *ngIf="!editMode && permission?.permitted && userCompetence" type="button" mat-raised-button
                  class="btn btn-info" onclick="document.getElementById('cfile').click();">Importa</button>
                <input type="file" #cfile id="cfile" #file (change)="convert($event)" style="display: none;">


                <button style="margin-right: 10px;" mat-raised-button type="button" (click)="save(false,'prev')"
                  *ngIf="editMode && !disableSave" class="btn btn-success" [disabled]="currentIdInList<=0">
                  <i style="margin-top: -1.6rem" class="material-icons">skip_previous</i>
                </button>

                <button style="margin-right: 10px;" mat-raised-button type="button" (click)="save(false,'next')"
                  *ngIf="editMode && !disableSave" class="btn btn-success"
                  [disabled]="currentIdInList>=ids_list.length-1">
                  <i class="material-icons" style="margin-top: -1.6rem">skip_next</i>
                </button>

                <button style="margin-right: 10px;" mat-raised-button type="submit" class="btn btn-success"
                  *ngIf="((permission?.permitted && userCompetence) || permission?.permitStatusChange) && !disableSave">Salva</button>

                <a (click)="close()"><i class="material-icons">close</i></a>
                <!--
                <button style="margin-right: 10px;" mat-raised-button type="button" (click)="close()"
                  class="btn btn-success"><i class="material-icons">
                    close
                  </i></button>
                -->

              </div>
            </h4>

          </div>
          <div class="card-body" style="width: 100%!important;" *ngIf="loading && !adzResult">
            <div class="row">
              <div class="col-sm-2" style="margin:auto">
                <br><br><br>
                <br><br><br>
                <!--
                <mat-spinner *ngIf="isMSIE()"></mat-spinner>
                <mat-progress-spinner mode="indeterminate" [value]="loading_percentage" *ngIf="!isMSIE()">
                </mat-progress-spinner>
                -->
                <br>
                <h3 style="text-align: center;">Caricamento in corso...</h3>
                <mat-progress-bar *ngIf="loading_percentage > 0" [value]="loading_percentage"> </mat-progress-bar>
                <h3 style="text-align: center;">{{loading_percentage | number: '.0-0'}}%</h3>

              </div>
            </div>

          </div>
          <div class="card-body" style="width: 100%!important; height: 100%;" [hidden]="loading">

            <div class="row">

              <div class="col-sm-4">

                <mat-form-field class="full">
                  <mat-label>Targa</mat-label>
                  <input type="text" matInput placeholder="Targa" formControlName="targa" [(ngModel)]="item.targa"
                    [readonly]="permission?.tabs[0] == 2 || !permission?.permitted">

                  <button type="button" mat-button matSuffix mat-icon-button (click)="etax_search_targa()"
                    *ngIf=" commessa?.etax === true && (userService.getCurrentUser().role == 'ADMIN' || userService.getCurrentUser().role == 'GESTORE') ">
                    <i class="material-icons"> search </i>
                  </button>

                </mat-form-field>



              </div>


              <div class="col-sm-4">
                <mat-form-field class="full">
                  <mat-label>Telaio</mat-label>
                  <input type="text" matInput placeholder="Telaio" formControlName="telaio" [(ngModel)]="item.telaio"
                    [readonly]="permission?.tabs[0] == 2 || !permission?.permitted">
                </mat-form-field>
              </div>

              <div class="col">
                <mat-form-field class="full">
                  <mat-label>Stato</mat-label>

                  <mat-select placeholder="Stato" [(ngModel)]="item.stato" formControlName="stato"
                    *ngIf="permission?.permitStatusChange">
                    <mat-option *ngFor="let i of stati" [value]="i">
                      {{i}}
                    </mat-option>
                  </mat-select>

                  <input type="text" matInput *ngIf="!permission?.permitStatusChange" [(ngModel)]="item.stato"
                    formControlName="stato" readonly>

                </mat-form-field>
              </div>

              <div class="col" *ngIf="(db.getIcon()=='gg' || db.getIcon()=='test' )  && item?.cliente==1">
                <mat-form-field class="full">
                  <mat-label>Stato Est.</mat-label>

                  <mat-select placeholder="Stato Est." [(ngModel)]="item.stato1" formControlName="stato1"
                    *ngIf="permission?.permitStatusChange">
                    <mat-option *ngFor="let i of stati1" [value]="i">
                      {{i}}
                    </mat-option>
                  </mat-select>

                  <input type="text" matInput *ngIf="!permission?.permitStatusChange" [(ngModel)]="item.stato1"
                    formControlName="stato1" readonly>

                </mat-form-field>
              </div>

            </div>

            <div class="row">

              <div class="col-sm-3"
                *ngIf=" (clienti.length>=2 && (userService.getCurrentUser().role != 'ISPETTORE' && userService.getCurrentUser().role != 'SALA CONTROLLO') || id==0 || id==undefined) ">
                <mat-form-field class="full">
                  <mat-label>Cliente </mat-label>
                  <mat-select placeholder="Cliente" [(ngModel)]="item.cliente" formControlName="cliente" id="cliente"
                    (ngModelChange)="clienteChange($event)"
                    [disabled]="permission?.tabs[0] == 2 || !permission?.permitted">
                    <mat-option value=""></mat-option>
                    <mat-option *ngFor="let i of clienti" [value]="i.id">
                      {{i.ragioneSociale}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-sm-3" *ngIf="permission?.assignments[0]?.permitView">
                <mat-form-field class="full">
                  <mat-label>Sala controllo</mat-label>
                  <mat-select placeholder="Sala controllo" [(ngModel)]="item.assegnatoA1" formControlName="assegnatoA1"
                    id="assegnatoA1" [disabled]="!permission?.assignments[0]?.permitChange"
                    (ngModelChange)="manageStatus('assegnatoA1',$event)">
                    <mat-option value=""></mat-option>
                    <mat-option *ngFor="let i of salacontrollo" [value]="i.email">
                      {{i.nome}} {{i.cognome}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-sm-3" *ngIf="permission?.assignments[1]?.permitView">

                <div class="row">

                  <div class="col">
                    <mat-form-field class="full">
                      <mat-label>Ispettore ({{ (ispettori| userFilterByParent: [item.assegnatoA1,'']).length
                        }})</mat-label>
                      <mat-select placeholder="Ispettore" [(ngModel)]="item.assegnatoA2" formControlName="assegnatoA2"
                        id="assegnatoA2" [disabled]="!permission?.assignments[1]?.permitChange"
                        (ngOpen)="sortIspettori()" (ngModelChange)="manageStatus('assegnatoA2',$event)">
                        <mat-option value=""></mat-option>
                        <ng-container
                          *ngFor="let i of ispettori | userFilterByParent: [item.assegnatoA1, item?.provincia.replace('MICM','MI')] ">
                          <mat-option [value]="i.email"
                            [ngStyle]="{'font-weight': i.province?.includes(item.provincia.replace('MICM','MI'))   ? 'bold' : '' }">
                            {{i.nome}} {{i.cognome}} {{i.genitore == '' ? '' : '[SC]'}} ({{i.province}})
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <!--
                  <div class="col-1" style="margin-left:0!important;">
                    <button mat-icon-button type="button" (click)="autoAssign()"><i class="material-icons">
                        location_searching
                      </i></button>
                  </div>
-->
                </div>




              </div>






              <div class="col-sm-3" [ngClass]="{'hidden': !permission?.assignments[1]?.permitView}">
                <mat-form-field class="full">
                  <mat-label>Commessa</mat-label>
                  <mat-select placeholder="Ispettore" [(ngModel)]="item.commessa" formControlName="commessa"
                    id="commessa" [disabled]="!permission?.assignments[1]?.permitChange"
                    (ngModelChange)="manageCommessa($event)">
                    <mat-option *ngFor="let i of commesse" [value]="i.id">{{i.descrizione}}</mat-option>
                  </mat-select>



                </mat-form-field>

              </div>



            </div>

            <div class="row" *ngIf="permission?.permitted && userCompetence && (stato?.accept || stato?.reject)">

              <div class="col-12" style="text-align: right">

                <button style="margin-right: 10px;" mat-raised-button type="button" class="btn btn-success"
                  *ngIf="permission?.permitted && userCompetence && stato?.accept && !(type=='unipolrental' && (stato?.accept == 'INCARICO ACCETTATO' || stato?.accept == 'CONFERMATO' ) )"
                  (click)="accept_reject(stato?.accept, stato?.accept_sendMessageTo)">{{stato?.accept}}</button>

                <button style="margin-right: 10px;" mat-raised-button type="button" class="btn btn-danger"
                  *ngIf="permission?.permitted && userCompetence && stato?.reject"
                  (click)="accept_reject(stato?.reject, stato?.reject_sendMessageTo)">{{stato?.reject}}</button>

              </div>


            </div>

            <p *ngIf="((item?.urMaxRiaddebitabile && item?.urMaxRiaddebitabile != '') || (item?.noteDeroghe && item?.noteDeroghe != ''))
             && (userService.getCurrentUser().role == 'ADMIN' || userService.getCurrentUser().role == 'GESTORE') "
              class="deroghe">
              DEROGHE<br>
              {{item.urMaxRiaddebitabile}} <span *ngIf="item?.noteDeroghe"> - {{item?.noteDeroghe}}</span>

            </p>

            <p *ngIf="reclamo?.reclamo1_dta &&
                (userService.getCurrentUser().role == 'ADMIN' || userService.getCurrentUser().role == 'GESTORE' || userService.getCurrentUser().role == 'CLIENTE') "
              class="deroghe">

              <button type="button" class="btn" (click)="toggleReclamo()">Visualizza Chiarimenti</button>

            </p>

            <p *ngIf="elenco_perizie_stessa_targa?.length>0 && 
              (
                userService.getCurrentUser().role == 'ADMIN' || 
                userService.getCurrentUser().role == 'GESTORE' || 
                userService.getCurrentUser().role == 'SALACONTROLLO'|| 
                userService.getCurrentUser().role == 'ISPETTORE'
              )" style="text-align: center;" class="stessa-targa">

              Attenzione, sono presenti altre {{elenco_perizie_stessa_targa.lenght}}
              pratiche con la stessa targa <br><br>

              <button type="button" class="btn" (click)="toggleElencoStessaTarga()">
                Visualizza
              </button>

            </p>

            <mat-tab-group (selectedTabChange)="onLinkClick($event)" [selectedIndex]="+tab"
              [backgroundColor]="'primary'" [color]="'danger'">

              <mat-tab label='Dati veicolo' [disabled]="!permission?.tabs[0]" style="height: 100%"><br>

                <div class="row">

                  <div class="col-sm-6 card">
                    <div class="card-header card-header-info">
                      <h5 class="card-title">Veicolo</h5>
                    </div><br>

                    <mat-form-field class="full">
                      <mat-label>Tipo</mat-label>
                      <mat-select [disabled]="permission?.tabs[0] == 2 || !permission?.permitted" placeholder="Tipo"
                        [(ngModel)]="item.tipoVeicolo" formControlName="tipoAuto"
                        (ngModelChange)="selectTipoChange($event)">
                        <mat-option value="auto">Automobile</mat-option>
                        <mat-option value="moto">Moto</mat-option>
                        <mat-option value="vcom">Veicoli Commerciali</mat-option>
                        <mat-option value="vind">Veicoli Industriali</mat-option>
                      </mat-select>
                    </mat-form-field>


                    <mat-form-field class="full">
                      <mat-label>{{getPlaceholder(item?.marca,'Marca')}}</mat-label>
                      <mat-select [disabled]="permission?.tabs[0] == 2 || !permission?.permitted" placeholder="Marca"
                        [(ngModel)]="item.marca" formControlName="marca" (ngModelChange)="selectMarcaChange($event)">
                        <mat-option *ngFor="let i of marche" [value]="i.acronimo">
                          {{i.nome}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>


                    <mat-form-field class="full">
                      <mat-label>{{getPlaceholder(item?.annoImmatricolazione,'Anno')}}</mat-label>
                      <mat-select [disabled]="permission?.tabs[0] == 2 || !permission?.permitted" placeholder="Anno"
                        [(ngModel)]="item.annoImmatricolazione" formControlName="annoImmatricolazione"
                        (ngModelChange)="selectAnnoChange($event)">
                        <mat-option *ngFor="let i of anni" [value]="i.anno">
                          {{i.anno}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>


                    <mat-form-field class="full">
                      <mat-label>{{getPlaceholder(item?.modello,'Modello')}}</mat-label>
                      <mat-select [disabled]="permission?.tabs[0] == 2 || !permission?.permitted" placeholder="Modello"
                        [(ngModel)]="item.modello" formControlName="modello"
                        (ngModelChange)="selectModelloChange($event)">
                        <mat-option *ngFor="let i of modelli" [value]="i.cod_gamma_mod">
                          {{i.desc_gamma_mod}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div>
                      <mat-spinner [diameter]="30" *ngIf="spinner"></mat-spinner>
                    </div>


                    <mat-form-field class="full" *ngIf="!spinner">
                      <mat-label>{{getPlaceholder(item?.versione,'Versione')}}</mat-label>
                      <mat-select [disabled]="permission?.tabs[0] == 2 || !permission?.permitted" placeholder="Versione"
                        [(ngModel)]="item.versione" formControlName="versione">
                        <mat-option *ngFor="let i of versioni" [value]="i.CodiceEurotax">
                          {{i.Nome}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>


                    <mat-label style="margin-left:20px">*Note Veicolo: {{item.marca}} {{item.annoImmatricolazione}}
                      {{item.modello}} {{item.versione}} </mat-label>
                    <hr>

                    <mat-form-field class="full">
                      <mat-label>Tipo perizia</mat-label>
                      <mat-select [disabled]="permission?.tabs[0] == 2 || !permission?.permitted"
                        placeholder="Tipo perizia" [(ngModel)]="item.tipo" formControlName="tipo">
                        <mat-option *ngFor="let i of db.getTipoPerizia(type)" [value]="i">
                          {{i}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>





                    <mat-form-field class="full">
                      <mat-label>Km</mat-label>
                      <input [readonly]="permission?.tabs[0] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Km" formControlName="km" [(ngModel)]="item.km">

                      <button type="button" mat-button matSuffix mat-icon-button (click)="etax_calcola()"
                        *ngIf=" commessa?.etax === true && (userService.getCurrentUser().role == 'ADMIN' || userService.getCurrentUser().role == 'GESTORE') ">
                        <i class="material-icons"> calculate </i>
                      </button>

                    </mat-form-field>

                    <div class="row" style="margin-left:15px;margin-right:15px; font-weight: bold;"
                      *ngIf="item?.valutazione_etax">
                      <div class="col"
                        style="background-color:#fbdd00; color:black; padding:10px; border-radius:15px;box-shadow:         2px 2px 9px 0px rgba(137, 137, 137, 0.51);">
                        ETAX Giallo:
                        <span style="float:right">
                          {{item?.valutazione_etax?.valutazione?.quotazione_eurotax_giallo|
                          number:'.2-2'}}€
                        </span>

                        <br>
                        ETAX Giallo (Km):
                        <span style="float:right">
                          {{item?.valutazione_etax?.valutazione?.quotazione_eurotax_giallo_km| number:'.2-2'}}€
                        </span> <br>
                      </div>
                      <div class="col"
                        style="background-color:#00059e; color:white; padding:10px; border-radius:15px; margin-left:5px;box-shadow:         2px 2px 9px 0px rgba(137, 137, 137, 0.51);">
                        ETAX Blu:
                        <span style="float:right">{{item?.valutazione_etax?.valutazione?.quotazione_eurotax_blu|
                          number:'.2-2'}}€ </span><br>
                        ETAX Blu (Km):
                        <span style="float:right"> {{item?.valutazione_etax?.valutazione?.quotazione_eurotax_blu_km|
                          number:'.2-2'}}€</span>
                        <br>
                      </div>
                      <div class="col-12">
                        * Periodo di riferimento: {{item?.valutazione_etax?.valutazione?.ediz_dati}}
                      </div>
                    </div>

                    <mat-form-field class="full">
                      <mat-label>Note</mat-label>
                      <textarea [readonly]="permission?.tabs[0] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Note" formControlName="nPerizia" [(ngModel)]="item.nPerizia"></textarea>
                    </mat-form-field>


                    <mat-form-field class="full">
                      <mat-label>MAD - Targa</mat-label>
                      <input type="text" matInput placeholder="MAD - Targa" formControlName="madTarga"
                        [(ngModel)]="item.madTarga">
                    </mat-form-field>

                  </div>

                  <div class="col-sm-6 card">
                    <div class="card-header card-header-success">
                      <h5 class="card-title">
                        Luogo ispezione
                        <button [disabled]="permission?.tabs[0] == 2 || !permission?.permitted"
                          *ngIf="type!='unipolrental'" style="float:right; margin-right: 10px;" mat-raised-button
                          type="button" class="btn btn-info" (click)="openDepotDialog()">
                          <i style="margin-top: -1.5rem;" class="material-icons"> search </i>
                        </button>


                        <button [disabled]="permission?.tabs[0] == 2 || !permission?.permitted"
                          *ngIf="type=='unipolrental'" style="float:right; margin-right: 10px;" mat-raised-button
                          type="button" class="btn btn-info" (click)="openMapSelector()">
                          <i style="margin-top: -1.5rem;" class="material-icons"> map </i> </button>
                      </h5>
                    </div><br>


                    <app-location-search *ngIf="showLocationSearch" [location_lat]="item.location_lat"
                      [location_lon]="item.location_lon" (onSelected)="onLocationSelected('luogo_ispezione',$event)"
                      [disabled]="permission?.tabs[0] == 2 || !permission?.permitted">
                    </app-location-search>


                    <mat-form-field class="full">
                      <mat-label>Provincia</mat-label>
                      <input matInput type="text"
                        [readonly]="permission?.tabs[0] == 2 || !permission?.permitted || item.codiceCentro > 0"
                        (change)="resetFiliale()" placeholder="Provincia" [(ngModel)]="item.provincia"
                        formControlName="provincia">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Comune</mat-label>
                      <input matInput type="text"
                        [readonly]="permission?.tabs[0] == 2 || !permission?.permitted || item.codiceCentro > 0"
                        placeholder="Comune" [(ngModel)]="item.citta" formControlName="citta"
                        (change)="selectComuneIspezioneChange($event)">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>CAP</mat-label>
                      <input matInput type="text"
                        [readonly]="permission?.tabs[0] == 2 || !permission?.permitted || item.codiceCentro > 0"
                        placeholder="CAP" [(ngModel)]="item.cap" formControlName="cap">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Indirizzo</mat-label>
                      <input [readonly]="permission?.tabs[0] == 2 || !permission?.permitted || item.codiceCentro > 0"
                        type="text" matInput placeholder="Indirizzo" formControlName="indirizzo"
                        [(ngModel)]="item.indirizzo">
                    </mat-form-field>

                    <div class="row" style="margin-left:10px;" hidden=true>

                      <div class="col-5">
                        <mat-form-field class="full">
                          <mat-label>Localiazzazione</mat-label>
                          <input type="number" matInput placeholder="Lat" formControlName="location_lat"
                            [(ngModel)]="item.location_lat">
                        </mat-form-field>
                      </div>

                      <div class="col-5">
                        <mat-form-field class="full">
                          <mat-label>Localiazzazione</mat-label>
                          <input type="number" matInput placeholder="Lon" formControlName="location_lon"
                            [(ngModel)]="item.location_lon">
                        </mat-form-field>
                      </div>

                      <div class="col-2">
                        <button type="button" mat-icon-button (click)="searchItineraryItem()">
                          <i class="material-icons">
                            search
                          </i>
                        </button>
                      </div>

                    </div>

                    <mat-form-field class="full">
                      <mat-label>Note</mat-label>
                      <input [readonly]="permission?.tabs[0] == 2 || !permission?.permitted || item.codiceCentro > 0"
                        type="text" matInput placeholder="Note" formControlName="noteCarico"
                        [(ngModel)]="item.noteCarico">
                    </mat-form-field>


                    <div class="col-12">
                      <mat-slide-toggle style="margin-left: 25px; margin-bottom:10px" [(ngModel)]="item.documentale"
                        (ngModelChange)="documentaleChange($event)" formControlName="documentale">Documentale
                      </mat-slide-toggle>
                    </div>



                  </div>
                </div>
              </mat-tab>

              <mat-tab label='Contatti' style="height: 100%;">
                <br>

                <ng-template matTabLabel>
                  <span [matBadge]="contatti_source.length" matBadgeOverlap="false"
                    [matBadgeColor]="areEmailInvalid ? 'accent' : 'primary'">Contatti</span>
                </ng-template>

                <ag-grid-angular style="width: 100%; height: calc(100vh - 450px);" class="ag-theme-alpine"
                  *ngIf="selectedTab_name=='Contatti'" [rowData]="contatti_source" [columnDefs]="contatti_columnDefs"
                  [defaultColDef]="defaultColDef" (gridReady)="onGridReady_contatti($event)">
                </ag-grid-angular>

                <eco-fab-speed-dial *ngIf="id!=0 && permission?.permitContatsEdit"
                  style="z-index: 999; position:absolute; right:15px; bottom:15px;" animationMode="fling">
                  <eco-fab-speed-dial-trigger>
                    <button type="button" mat-fab color="warn" (click)="onContactEditDialog()">
                      <mat-icon>add</mat-icon>
                    </button>
                  </eco-fab-speed-dial-trigger>
                </eco-fab-speed-dial>



              </mat-tab>

              <mat-tab label="Dati sinistro" *ngIf="type=='perizie'" [disabled]="!permission?.tabs[1]"
                style="height: 100%" [ngClass]="{'hidden': type!='perizie'}"><br>
                <div class="row">
                  <div class="col-sm-4 card">
                    <div class="card-header card-header-primary">
                      <h5 class="card-title">Sinistro</h5>
                    </div><br>



                    <mat-form-field class="full">
                      <mat-label>Numero sinistro</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Numero sinistro" (ngModelChange)="claimNrChange($event)"
                        formControlName="sinistro_numero" [(ngModel)]="item.sinistro_numero">

                      <i *ngIf="errorClaimNr" class="material-icons" style="float:right; color:red">error</i>

                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Partita di danno</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="number" matInput
                        placeholder="Partita di danno" formControlName="partita_danno" [(ngModel)]="item.partita_danno">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Mandante sinistro</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Mandante sinistro" formControlName="sinistro_mandante"
                        [(ngModel)]="item.sinistro_mandante">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Data sinistro</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="date" matInput
                        placeholder="Data sinistro" formControlName="sinistro_data" [(ngModel)]="item.sinistro_data">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Targa Assicurato</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Targa Assicurato" formControlName="sinistro_targaAssicurato"
                        [(ngModel)]="item.sinistro_targaAssicurato">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Nome Assicurato</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Nome Assicurato" formControlName="sinistro_nomeAssicurato"
                        [(ngModel)]="item.sinistro_nomeAssicurato">
                    </mat-form-field>

                  </div>

                  <!--------------------------------------------->


                  <div class="col-sm-4 card">
                    <div class="card-header card-header-info">
                      <h5 class="card-title">Danneggiato</h5>
                    </div><br>

                    <mat-form-field class="full">
                      <mat-label>Nominativo</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Nominativo" formControlName="danneggiato_nome" [(ngModel)]="item.danneggiato_nome">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Provincia</mat-label>
                      <mat-select [disabled]="permission?.tabs[1] == 2 || !permission?.permitted"
                        placeholder="Provincia" [(ngModel)]="item.danneggiato_provincia"
                        formControlName="danneggiato_provincia"
                        (ngModelChange)="selectProvinciaDanneggiatoChange($event)">
                        <mat-option *ngFor="let i of province" [value]="i.sigla">
                          {{i.sigla}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Comune</mat-label>
                      <mat-select [disabled]="permission?.tabs[1] == 2 || !permission?.permitted" placeholder="Comune"
                        [(ngModel)]="item.danneggiato_citta" formControlName="danneggiato_citta"
                        (ngModelChange)="selectComuneDanneggiatoChange($event)">
                        <mat-option *ngFor="let i of comuniDanneggiato" [value]="i.nome">
                          {{i.nome}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>CAP</mat-label>
                      <mat-select [disabled]="permission?.tabs[1] == 2 || !permission?.permitted" placeholder="CAP"
                        [(ngModel)]="item.danneggiato_cap" formControlName="danneggiato_cap">
                        <mat-option *ngFor="let i of capDanneggiato" [value]="i">
                          {{i}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Indirizzo</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Indirizzo" formControlName="danneggiato_indirizzo"
                        [(ngModel)]="item.danneggiato_indirizzo">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Telefono</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Telefono" formControlName="danneggiato_telefono"
                        [(ngModel)]="item.danneggiato_telefono">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Fax</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Fax" formControlName="danneggiato_fax" [(ngModel)]="item.danneggiato_fax">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Email</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="email" matInput
                        placeholder="Email" formControlName="danneggiato_email" [(ngModel)]="item.danneggiato_email">
                    </mat-form-field>

                  </div>

                  <!--------------------------------------------->

                  <div class="col-sm-4 card">
                    <div class="card-header card-header-success">
                      <h5 class="card-title">Patrocinatore</h5>
                    </div><br>

                    <mat-form-field class="full">
                      <mat-label>Nominativo</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Nominativo" formControlName="patrocinatore_nome"
                        [(ngModel)]="item.patrocinatore_nome">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Provincia</mat-label>
                      <mat-select [disabled]="permission?.tabs[1] == 2 || !permission?.permitted"
                        placeholder="Provincia" [(ngModel)]="item.patrocinatore_provincia"
                        formControlName="patrocinatore_provincia"
                        (ngModelChange)="selectProvinciaPatrocinatoreChange($event)">
                        <mat-option *ngFor="let i of province" [value]="i.sigla">
                          {{i.sigla}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Comune</mat-label>
                      <mat-select [disabled]="permission?.tabs[1] == 2 || !permission?.permitted" placeholder="Comune"
                        [(ngModel)]="item.patrocinatore_citta" formControlName="patrocinatore_citta"
                        (ngModelChange)="selectComunePatrocinatoreChange($event)">
                        <mat-option *ngFor="let i of comuniPatrocinatore" [value]="i.nome">
                          {{i.nome}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>CAP</mat-label>
                      <mat-select [disabled]="permission?.tabs[1] == 2 || !permission?.permitted" placeholder="CAP"
                        [(ngModel)]="item.patrocinatore_cap" formControlName="patrocinatore_cap">
                        <mat-option *ngFor="let i of capPatrocinatore" [value]="i">
                          {{i}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Indirizzo</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Indirizzo" formControlName="patrocinatore_indirizzo"
                        [(ngModel)]="item.patrocinatore_indirizzo">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Telefono</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Telefono" formControlName="patrocinatore_telefono"
                        [(ngModel)]="item.patrocinatore_telefono">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Fax</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Fax" formControlName="patrocinatore_fax" [(ngModel)]="item.patrocinatore_fax">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Email/PEC</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="email" matInput
                        placeholder="Email/PEC" formControlName="patrocinatore_email"
                        [(ngModel)]="item.patrocinatore_email">
                    </mat-form-field>
                  </div>
                </div>


              </mat-tab>


              <mat-tab label='Luogo sinistro' [disabled]="!permission?.tabs[2] " style="height: 100%"
                *ngIf="type != 'ssu' && type != 'tradein'  && type != 'uxpert' && type != 'unipolrental'  && type != 'buyback' "><br>

                <div *ngIf=" selectedTab_name == 'Luogo sinistro' ">
                  <app-location-search [location_lat]="item.sinistro_location_lat"
                    [location_lon]="item.sinistro_location_lon"
                    *ngIf="  item.sinistro_location_lat && item.sinistro_location_lat && item.sinistro_location_lon!=0 && item.sinistro_location_lon!=0 || id == 0 "
                    (onSelected)="onLocationSelected('luogo_sinistro',$event)" [disabled]="permission?.tabs[2] == 2">
                  </app-location-search>


                </div>

                <mat-form-field class="full">
                  <mat-label>Provincia</mat-label>
                  <input matInput type="text" [readonly]="permission?.tabs[2] == 2" placeholder="Provincia"
                    [(ngModel)]="item.sinistro_provincia" formControlName="sinistro_provincia">
                </mat-form-field>

                <mat-form-field class="full">
                  <mat-label>Comune</mat-label>
                  <input matInput type="text" [readonly]="permission?.tabs[2] == 2" placeholder="Comune"
                    [(ngModel)]="item.sinistro_citta" formControlName="sinistro_citta"
                    (change)="selectComuneIspezioneChange($event)">
                </mat-form-field>

                <mat-form-field class="full">
                  <mat-label>CAP</mat-label>
                  <input matInput type="text" [readonly]="permission?.tabs[2] == 2" placeholder="CAP"
                    [(ngModel)]="item.sinistro_cap" formControlName="sinistro_cap">
                </mat-form-field>

                <mat-form-field class="full">
                  <mat-label>Indirizzo</mat-label>
                  <input [readonly]="permission?.tabs[2] == 2" type="text" matInput placeholder="Indirizzo"
                    formControlName="sinistro_indirizzo" [(ngModel)]="item.sinistro_indirizzo">
                </mat-form-field>

                <div class="row" style="margin-left:10px;">

                  <div class="col-5">
                    <mat-form-field class="full">
                      <mat-label>Localiazzazione</mat-label>
                      <input type="number" matInput placeholder="Lat" formControlName="sinistro_location_lat"
                        [readonly]="permission?.tabs[2] == 2" [(ngModel)]="item.sinistro_location_lat">
                    </mat-form-field>
                  </div>

                  <div class="col-5">
                    <mat-form-field class="full">
                      <mat-label>Localiazzazione</mat-label>
                      <input type="number" matInput placeholder="Lon" formControlName="sinistro_location_lon"
                        [readonly]="permission?.tabs[2] == 2" [(ngModel)]="item.sinistro_location_lon">
                    </mat-form-field>
                  </div>
                </div>


              </mat-tab>

              <mat-tab label="Documenti incarico" [disabled]="!permission?.tabs[3]" *ngIf="type != 'unipolrental'"
                style="height: 100%">

                <ng-template matTabLabel>
                  <span [matBadge]="item?.allegatiIncarico?.length" matBadgeOverlap="false">Documenti incarico</span>
                </ng-template>

                <div class="row" style="margin-left:10px;">
                  <div class="col-sm-12">
                    <app-gallery [disabled]="permission?.tabs[2] == 2" [(source)]="item.allegatiIncarico"
                      [filters]=" ['img', 'pdf', 'oth', 'adz']" [customDocTypes]="['cai','mod. concordato']"
                      (onDelete)="save(false)" (onSelected)=openViewer($event,item.allegatiIncarico)
                      [concordato]="'#8FA4BA'"></app-gallery>
                  </div>
                </div>
              </mat-tab>

              <mat-tab label="Interlocutorie" *ngIf="permission?.tabs[9] && type=='perizie'"> <br>

                <ng-template matTabLabel>
                  <span [matBadge]="ninterloc" matBadgeOverlap="false">Interlocutorie</span>
                </ng-template>

                <div>
                  <h4>Interlocutorie</h4>
                  <!-- <ng2-smart-table class="grid" [settings]="inter_settings" [source]="inter_source"
                    (create)="openInterDialog()" (edit)="downloadInter($event)">
                  </ng2-smart-table> -->
                </div>

                <div *ngIf="userService.getRole() != 'CLIENTE'">
                  <hr>
                  <h4>Invito a perizia ({{userService.getRole()}})</h4>
                  <!-- <ng2-smart-table class="grid" [settings]="inviti_settings" [source]="inviti_source"
                    (create)="openInvitoDialog()">
                  </ng2-smart-table> -->
                </div>

              </mat-tab>
              <mat-tab label="Appuntamento" *ngIf="permission?.tabs[4] && type != 'buyback'"> <br>

                <zone>

                  <div class="row">
                    <div class="col">
                      <p>
                        <span class="legenda" (click)="legend=!legend">Legenda</span>
                        <span class="dta-ctr" style="margin-left: 0.5rem;" (click)="openMapSelector()">
                          MODIFICA CENTRO
                        </span>
                        <mat-slide-toggle style="margin-left: 0.2rem;" class="toggle-history"
                          (change)="onVisualizzaStoricoChange($event)" [checked]="visualizzaStorico"
                          [disabled]="disabled">
                        </mat-slide-toggle> Visualizza storico

                        &nbsp;
                        <span class="dta-app" *ngIf="item.dtaAppuntamento">Data App.: {{item.dtaAppuntamento | date:
                          'dd/MM/yyyy HH:mm': '+0000'}} </span>

                        &nbsp;

                        <span *ngIf="item.urScadenzaContratto" class="dta-scad">
                          Data scadenza contratto: {{ item.urScadenzaContratto | date: 'dd/MM/yyyy' }} - Tipo
                          consegna: {{item?.ServizioRiconsegna}}
                        </span>

                        &nbsp;


                      </p>
                    </div>


                    <div class="col">
                      <mat-form-field class="full" style="width: 50%; float: right;" *ngIf="item.assegnatoA2 == ''">
                        <mat-label>Ispettore ({{ (ispettori| userFilterByParent: [item.assegnatoA1,'']).length
                          }})</mat-label>
                        <mat-select placeholder="Ispettore" [(ngModel)]="calendarFilterData.ispettore"
                          formControlName="filterIsp" [disabled]="!permission?.assignments[1]?.permitChange"
                          (ngOpen)="sortIspettori()" (ngModelChange)="filterCalendar()">
                          <mat-option value=""></mat-option>
                          <ng-container
                            *ngFor="let i of ispettori | userFilterByParent: [item.assegnatoA1, item?.provincia.replace('MICM','MI')] ">
                            <mat-option [value]="i.email"
                              [ngStyle]="{'font-weight': i.province?.includes(item.provincia.replace('MICM','MI'))   ? 'bold' : '' }">
                              {{i.nome}} {{i.cognome}} {{i.genitore == '' ? '' : '[SC]'}} ({{i.province}})
                            </mat-option>
                          </ng-container>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <!-- non utile al momento
                  <div class="col">
                    <mat-form-field class="full">
                      <mat-label>Centro ({{centri?.length}})</mat-label>
                      <mat-select placeholder="Centro" [(ngModel)]="calendarFilterData.centro"
                        [disabled]="!permission?.assignments[1]?.permitChange" (ngModelChange)="filterCalendar()">
                        <mat-option value=""></mat-option>
                        <ng-container *ngFor="let i of centri">
                          <mat-option [value]="i.CODICE_AZIENDALE_UNITA">
                            {{i.RAGIONE_SOCIALE}}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  
-->

                    <div class="col-12">
                      <dx-scheduler [dataSource]="appointmentsData" [views]='["day", "week", "workWeek"]'
                        (onDragEnd)="appointmentUpdated($event)" currentView="workWeek" [currentDate]="currentDate"
                        [startDayHour]="8" [height]="'70vh'" firstDayOfWeek=1 showAllDayPanel=false
                        (onCellClick)="slotClick($event)" *ngIf="selectedTab_name=='Appuntamento'"
                        [timeZone]="'Etc/UCT'" appointmentTemplate="appointment-template"
                        appointmentTooltipTemplate="tooltip-template" [cellDuration]="30" [showAllDayPanel]="false"
                        dataCellTemplate="dataCellTemplate" (onOptionChanged)="onOptionChanged($event)">

                        <dxo-editing [allowAdding]="false"></dxo-editing>
                        <dxo-editing [allowUpdating]="false"></dxo-editing>
                        <dxo-editing [allowDeleting]="false"></dxo-editing>

                        <div *dxTemplate="let dataCell of 'dataCellTemplate'" [ngClass]="{ 
                        'before-date': isBeforeStartDate(dataCell.startDate),
                        'holiday-date': isHoliday(dataCell.startDate),
                        'disable-date': isDisabled(dataCell.startDate) == 'closed',
                        'oncall-date': isDisabled(dataCell.startDate) == 'call',
                        'scad-date': isBeforeContractDate(dataCell.startDate)
                      }">
                        </div>

                        <div *dxTemplate="let model of 'appointment-template'"
                          [ngStyle]="{'background-color': model.appointmentData.color}">
                          <div class="showtime-preview">
                            <div><strong>{{model.appointmentData.text}}
                                <span *ngIf="model.appointmentData.isMAD == 1">(MAD)</span>
                                <span *ngIf="model.appointmentData.tmp">
                                  (Provvisorio)</span></strong><br>
                              Isp: {{model.appointmentData.ispettoreDescr}}<br>
                              Presso: {{model.appointmentData.noteCarico}}<br>
                              {{model.appointmentData.address}}



                            </div>

                            <div *ngIf="model.appointmentData.tmp && model.appointmentData.id == item.id">

                              <div class="row" style="position:absolute; right:15px; bottom:0">
                                <button type="button" mat-icon-button class="btn btn-success" style=" display:inline"
                                  *ngIf="(userService.getCurrentUser().role == 'ADMIN' || userService.getCurrentUser().role == 'GESTORE' || userService.getCurrentUser().role == 'SALACONTROLLO'  || userService.getCurrentUser().role == 'ISPETTORE' ) && permission?.permitted && item?.stato != 'ANNULLATO' && item?.stato != 'RICHIESTA AUTORIZZAZIONE'  "
                                  (click)="confirmTemporaryAppointment(model.appointmentData)"
                                  [disabled]="(!item.assegnatoA2 || item.assegnatoA2 == '') && (!item.assegnatoA1 || item.assegnatoA1 == '')">
                                  <i class="material-icons">check</i>
                                </button>

                                <button type="button" mat-icon-button class="btn btn-danger"
                                  style=" display:inline; margin-left:5px"
                                  *ngIf="(userService.getCurrentUser().role == 'ADMIN' || userService.getCurrentUser().role == 'GESTORE' ) && permission?.permitted && item?.stato != 'ANNULLATO'  "
                                  (click)="cancelTemporaryAppointment(model.appointmentData)">
                                  <i class="material-icons">delete_forever</i>
                                </button>

                              </div>
                            </div>

                            <div *ngIf="!model.appointmentData.tmp && model.appointmentData.id == item.id">

                              <div class="row" style="position:absolute; right:15px; bottom:0">

                                <button type="button" mat-icon-button class="btn btn-danger"
                                  style=" display:inline; margin-left:5px"
                                  *ngIf="(userService.getCurrentUser().role == 'ADMIN' || userService.getCurrentUser().role == 'GESTORE') && permission?.permitted && item?.stato != 'ANNULLATO' "
                                  (click)="confirmTemporaryAppointment_rollback()">
                                  <i class="material-icons">delete_forever</i>
                                </button>

                              </div>
                            </div>



                          </div>
                        </div>

                        <div *dxTemplate="let model of 'tooltip-template'"
                          [ngStyle]="{'background-color': model.appointmentData.color}">
                          <div class="showtime-preview">
                            <div><strong>{{model.appointmentData.text}}
                                <span *ngIf="model.appointmentData.isMAD == 1">(MAD)</span>
                                <span *ngIf="model.appointmentData.tmp"> (Provvisorio)</span></strong><br>
                              Isp: {{model.appointmentData.ispettoreDescr}}<br>
                              Presso: {{model.appointmentData.noteCarico}}<br>
                              {{model.appointmentData.address}}


                            </div>
                          </div>
                        </div>

                      </dx-scheduler>
                    </div>
                  </div>

                </zone>
              </mat-tab>

              <mat-tab label="Doc Perizia"
                *ngIf="!(!(permission?.tabs[5] || ( item.stato == 'CONCLUSO' || item.stato == 'CONFERMATO' )))">



                <ng-template matTabLabel>
                  <span [matBadge]="item?.adz_data?.adz ? 'OK' : '-' " matBadgeOverlap="false">Doc Perizia</span>
                </ng-template>


                <!--

                <button type="button" mat-fab color="primary"
                 (click)="toggleAddAdz()">+</button>
                  
                -->
                <div class="row" style="margin-left:0;">


                  <eco-fab-speed-dial style="z-index: 999; position:absolute; left:0px; top:5px;" animationMode="fling"
                    *ngIf="(type=='unipolrental' && userService.getCurrentUser().role == 'ISPETTORE')" direction="down">
                    <!-- [fixed]="true" 
                    [open]="isEcoFabopened" (mouseenter)="ecoFabOpen()"
                    (mouseleave)="ecoFabClose()" -->
                    <eco-fab-speed-dial-trigger>
                      <button type="button" mat-fab color="primary">
                        <mat-icon>menu</mat-icon>
                      </button>
                    </eco-fab-speed-dial-trigger>

                    <eco-fab-speed-dial-actions>

                      <button type="button" mat-mini-fab (click)="toggleAddAdz()" color="primary"
                        *ngIf="item?.stato != 'CONCLUSO'" matTooltipClass="mytooltip" matTooltipPosition="right">
                        <mat-icon>add</mat-icon>
                      </button>


                      <button type="button" mat-mini-fab (click)="openReport()" color="primary"
                        *ngIf="item?.adz_data && userService.getCurrentUser().role != 'CLIENTE' &&  userService.getCurrentUser().role != 'MANDANTE' "
                        matTooltipClass="mytooltip" matTooltipPosition="right">
                        <mat-icon>archive</mat-icon>
                      </button>

                    </eco-fab-speed-dial-actions>
                  </eco-fab-speed-dial>




                  <eco-fab-speed-dial style="z-index: 999; position:absolute; left:0px; top:5px;" animationMode="fling"
                    *ngIf="!(type=='unipolrental' && userService.getCurrentUser().role == 'ISPETTORE')"
                    direction="down">
                    <!-- [fixed]="true" 
                    [open]="isEcoFabopened" (mouseenter)="ecoFabOpen()"
                    (mouseleave)="ecoFabClose()" -->
                    <eco-fab-speed-dial-trigger>
                      <button type="button" mat-fab color="primary">
                        <mat-icon>menu</mat-icon>
                      </button>
                    </eco-fab-speed-dial-trigger>

                    <eco-fab-speed-dial-actions>

                      <button type="button" mat-mini-fab (click)="livexpert()" color="primary"
                        *ngIf=" permission?.permitLiveXpert && live && !(db.getIcon()=='gg' || db.getIcon()=='gg-demo')"
                        matTooltipClass="mytooltip" matTooltipPosition="right">
                        <mat-icon>videocam</mat-icon>
                      </button>

                      <button type="button" mat-mini-fab (click)="livexpert()" color="primary"
                        *ngIf=" permission?.permitLiveXpert && live && (db.getIcon()=='gg' || db.getIcon()=='gg-demo')"
                        matTooltipClass="mytooltip" matTooltipPosition="right">
                        <mat-icon>videocam</mat-icon>
                      </button>

                      <button type="button" mat-mini-fab (click)="toggleAddAdz()" color="primary"
                        *ngIf="item?.stato != 'CONCLUSO'" matTooltipClass="mytooltip" matTooltipPosition="right">
                        <mat-icon>add</mat-icon>
                      </button>

                      <!-- SOLO DRIVE NOW -->
                      <button type="button" mat-mini-fab (click)="setAsWreck()" color="primary"
                        *ngIf=" ( ( item.cliente == 5 && db.getIcon() == 'ie' ) || ( item.cliente == 8 && db.getIcon() == 'gg' ) ) && userService.getCurrentUser().role == 'CLIENTE'"
                        matTooltipClass="mytooltip" matTooltipPosition="right">
                        <mat-icon>new_releases</mat-icon>
                      </button>

                      <button type="button" mat-mini-fab (click)="sendParcel()" color="primary"
                        *ngIf=" ( ( item.cliente == 5 && db.getIcon() == 'ie' ) || ( (item.cliente == 8 || item.cliente == 7 ) && db.getIcon() == 'gg' ) ) && userService.getCurrentUser().role == 'CLIENTE'"
                        matTooltipClass="mytooltip" matTooltipPosition="right">
                        <mat-icon>mail</mat-icon>
                      </button>
                      <!-- SOLO DRIVE NOW -->

                      <button type="button" mat-mini-fab (click)="openReport()" color="primary"
                        *ngIf="item?.adz_data && userService.getCurrentUser().role != 'CLIENTE' &&  userService.getCurrentUser().role != 'MANDANTE' "
                        matTooltipClass="mytooltip" matTooltipPosition="right">
                        <mat-icon>archive</mat-icon>
                      </button>

                      <!--
                      <button type="button" mat-mini-fab (click)="openReport()" color="primary" *ngIf="item?.adz_data && type == 'ssu'"
                        matTooltipClass="mytooltip" matTooltipPosition="right"
                        [matTooltip]="'Apri report'">
                        <mat-icon>archive</mat-icon>
                      </button>
                      
                      <button type="button" mat-mini-fab (click)="openReport()" color="primary" *ngIf="type != 'ssu'"
                        matTooltipClass="mytooltip" matTooltipPosition="right"
                        [matTooltip]="'Download ADZ'">
                        <mat-icon>archive</mat-icon>
                      </button>
-->
                      <button type="button" mat-mini-fab (click)="downloadZip('all')" color="primary"
                        *ngIf="type != 'buyback'" matTooltipClass="mytooltip" matTooltipPosition="right">
                        <mat-icon>save_alt</mat-icon>
                      </button>

                      <button type="button" mat-mini-fab (click)="downloadZip('img')" color="primary"
                        matTooltipClass="mytooltip" matTooltipPosition="right">
                        <mat-icon>camera_alt</mat-icon>
                      </button>

                      <button type="button" mat-mini-fab (click)="downloadZip('pdf-img')" color="primary"
                        matTooltipClass="mytooltip" matTooltipPosition="right" *ngIf="pdf_perizia ">
                        <mat-icon>poll</mat-icon>
                      </button>

                      <button type="button" mat-mini-fab (click)="downloadZip('pdf')" color="primary"
                        matTooltipClass="mytooltip" matTooltipPosition="right" *ngIf="pdf_perizia ">
                        <mat-icon>poll</mat-icon>
                      </button>

                      <!--
                      <button type="button" mat-mini-fab (click)="downloadZip('noval')" color="primary"
                        matTooltipClass="mytooltip" matTooltipPosition="right" *ngIf="pdf_perizia && type == 'buyback'">
                        <mat-icon>poll</mat-icon>
                      </button>
-->

                      <button type="button" mat-mini-fab (click)="reportRMK()" color="primary"
                        matTooltipClass="mytooltip" matTooltipPosition="right"
                        *ngIf="pdf_perizia  && type != 'buyback'">
                        <mat-icon>poll</mat-icon>
                      </button>

                      <button type="button" mat-mini-fab
                        *ngIf=" (userService.getCurrentUser().role == 'ADMIN' || userService.getCurrentUser().role == 'GESTORE')"
                        (click)="openCheckDialog()" color="primary" matTooltipClass="mytooltip"
                        matTooltipPosition="right">
                        <mat-icon>library_add_check</mat-icon>
                      </button>

                    </eco-fab-speed-dial-actions>
                  </eco-fab-speed-dial>

                  <div class="col-sm-5" style="overflow:auto; height: 65vh;" *ngIf="!item?.adz_data?.adz && !orderMode">



                    <br>


                    <div [ngClass]="{ 'card': dati_fatt_expanded }"
                      *ngIf=" userService.getCurrentUser().role == 'ADMIN'  ">
                      <div class="card-header card-header-info">
                        <h5 class="card-title" style="text-align: center;">Dati Fatturazione

                          <i class="material-icons" style="float:right"
                            (click)="dati_fatt_expanded = !dati_fatt_expanded">
                            {{ dati_fatt_expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
                          </i>

                        </h5>
                      </div>
                      <br>

                      <div style="display: flex; margin-bottom: 1rem;">
                        <button style="width:50%; float: left; margin-right: 0.5rem;"
                          *ngIf="type == 'unipolrental' && userService.getCurrentUser().role != 'CLIENTE'  && userService.getCurrentUser().role != 'MANDANTE' "
                          type="button" mat-raised-button class="btn btn-info" (click)="setShowSinistri()">Visualizza
                          Sinistri
                        </button><br>
                        <button style="width:50%; float: right;"
                          *ngIf="type == 'unipolrental' && userService.getCurrentUser().role != 'CLIENTE'  && userService.getCurrentUser().role != 'MANDANTE' "
                          type="button" mat-raised-button class="btn btn-info" (click)="setShowTariffe()">Visualizza
                          Tariffe
                        </button><br>
                      </div>


                      <div *ngIf="dati_fatt_expanded" style="margin-left: 3rem;">
                        <mat-form-field class="full" *ngIf="commessa?.costi_ricavi_manuali">
                          <mat-select placeholder="Seleziona tariffa" (selectionChange)="selTariffa($event)"
                            formControlName="sel_tariffa" [(ngModel)]="sel_tariffa">
                            <mat-option *ngFor="let i of commessa?.costi_ricavi_manuali" [value]="i">
                              {{i.descr}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field class="full">
                          <mat-label>Ricavo Manuale</mat-label>
                          <input type="number" matInput placeholder="Ricavo Manuale" formControlName="ricavo_manuale"
                            [(ngModel)]="item.ricavo_manuale">
                        </mat-form-field>

                        <mat-form-field class="full">
                          <mat-label>Costo Manuale</mat-label>
                          <input type="number" matInput placeholder="Costo Manuale" formControlName="costo_manuale"
                            [(ngModel)]="item.costo_manuale">
                        </mat-form-field>

                        <mat-form-field class="full">
                          <mat-label>Descrizione cambio tariffa</mat-label>
                          <input type="test" matInput placeholder="Descrizione cambio tariffa"
                            formControlName="descrizione_cambio_tariffa" [(ngModel)]="item.descrizione_cambio_tariffa">
                        </mat-form-field>
                        <button *ngIf="item?.cliente == 7 || item?.cliente == 8" type="button" mat-raised-button
                          class="btn btn-info" (click)="allegaParcella(item.ricavo_manuale)">Genera Parcella</button>
                      </div>



                    </div>


                    <hr>
                    <h5 style="text-align: center">Nessun file ADZ presente</h5>
                    <hr>

                    <div *ngIf="commessa">
                      <h5>Parametri:</h5>
                      <ul>
                        <li>Manodopera Meccanica: {{commessa.man_meccanica}}</li>
                        <li>Manodopera Carrozzeria: {{commessa.man_carrozzeria}}</li>
                        <li>Materiali di Consumo: {{commessa.mat_consumo}}</li>
                        <li>Note: {{commessa.note}}</li>
                      </ul>
                    </div>
                    <!--
                    <mat-slide-toggle formControlName="fotoComplete" [(ngModel)]="item.fotoComplete">Analisi
                      fotografica
                      completa</mat-slide-toggle>
-->
                  </div>

                  <div class="col" style="overflow:auto; height: auto;" *ngIf="item?.adz_data?.adz && !orderMode">

                    <br>
                    <br>

                    <div [ngClass]="{ 'card': dati_fatt_expanded }"
                      *ngIf=" userService.getCurrentUser().role == 'ADMIN' || userService.getCurrentUser().role == 'GESTORE' ">
                      <div class="card-header card-header-info">
                        <h5 class="card-title" style="text-align: center;">Dati Fatturazione

                          <i class="material-icons" style="float:right"
                            (click)="dati_fatt_expanded = !dati_fatt_expanded">
                            {{ dati_fatt_expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
                          </i>

                        </h5>
                      </div>
                      <br>

                      <div *ngIf="dati_fatt_expanded" style="margin-left: 3rem;">
                        <mat-form-field class="full" *ngIf="commessa?.costi_ricavi_manuali">
                          <mat-select placeholder="Seleziona tariffa" (ngModelChange)="selTariffa($event)"
                            formControlName="sel_tariffa" [(ngModel)]="sel_tariffa">
                            <mat-option *ngFor="let i of commessa.costi_ricavi_manuali" [value]="i">
                              {{i.descr}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field class="full">
                          <mat-label>Ricavo Manuale</mat-label>
                          <input type="number" matInput placeholder="Ricavo Manuale" formControlName="ricavo_manuale"
                            [(ngModel)]="item.ricavo_manuale">
                        </mat-form-field>

                        <mat-form-field class="full">
                          <mat-label>Costo Manuale</mat-label>
                          <input type="number" matInput placeholder="Costo Manuale" formControlName="costo_manuale"
                            [(ngModel)]="item.costo_manuale">
                        </mat-form-field>

                        <mat-form-field class="full">
                          <mat-label>Descrizione cambio tariffa</mat-label>
                          <input type="test" matInput placeholder="Descrizione cambio tariffa"
                            formControlName="descrizione_cambio_tariffa" [(ngModel)]="item.descrizione_cambio_tariffa">
                        </mat-form-field>
                        <button *ngIf="item?.cliente == 7 || item?.cliente == 8" type="button" mat-raised-button
                          class="btn btn-info" (click)="allegaParcella(item.ricavo_manuale)">Genera Parcella</button>
                      </div>




                    </div>



                    <div class="card"
                      *ngIf="type=='unipolrental' && (userService.getCurrentUser().role == 'ADMIN' || userService.getCurrentUser().role == 'GESTORE') ">
                      <div class="card-header card-header-info">
                        <h5 class="card-title" style="text-align: center;">Dati Riaddebito</h5>
                      </div>


                      <mat-form-field class="full">
                        <mat-label>Importo doppie chiavi</mat-label>
                        <input type="number" matInput placeholder="Importo doppie chiavi"
                          formControlName="Doppie_chiavi" [(ngModel)]="ria.Doppie_chiavi" readonly>
                        <button type="button" matSuffix mat-icon-button
                          [disabled]="!permission?.permitted || !userCompetence || item.stato == 'CONCLUSO'"
                          (click)="addAdzURRow('^^^ Doppie chiavi ^^^',300)">
                          <i class="material-icons ">{{ ria.Doppie_chiavi > 0 ? 'delete' : 'add' }}</i>
                        </button>
                      </mat-form-field>


                      <mat-form-field class="full">
                        <mat-label>Importo carta di circolazione</mat-label>
                        <input type="number" matInput placeholder="Importo concordato"
                          formControlName="Carta_di_circolazione" [(ngModel)]="ria.Carta_di_circolazione" readonly>
                        <button type="button" matSuffix mat-icon-button
                          [disabled]="!permission?.permitted || !userCompetence || item.stato == 'CONCLUSO'"
                          (click)="addAdzURRow('^^^ Carta di circolazione ^^^',300)">
                          <i class="material-icons ">{{ ria.Carta_di_circolazione > 0 ? 'delete' : 'add' }}</i>
                        </button>
                      </mat-form-field>

                      <mat-form-field class="full">
                        <mat-label>Importo ruota di scorta</mat-label>
                        <input type="number" matInput placeholder="Importo ruota di scorta"
                          formControlName="Ruota_scorta" [(ngModel)]="ria.Ruota_scorta" readonly>
                        <button type="button" matSuffix mat-icon-button
                          [disabled]="!permission?.permitted || !userCompetence || item.stato == 'CONCLUSO'"
                          (click)="addAdzURRow('^^^ Ruota di scorta ^^^')">
                          <i class="material-icons ">{{ ria.Ruota_scorta > 0 ? 'delete' : 'add' }}</i>
                        </button>
                      </mat-form-field>

                    </div>


                    <div style="display: flex; margin-bottom: 1rem;">
                      <button style="width:50%; float: left; margin-right: 0.5rem;"
                        *ngIf="type == 'unipolrental' && userService.getCurrentUser().role != 'CLIENTE'  && userService.getCurrentUser().role != 'MANDANTE' "
                        type="button" mat-raised-button class="btn btn-info" (click)="setShowSinistri()">Visualizza
                        Sinistri
                      </button><br>
                      <button style="width:50%; float: right;"
                        *ngIf="type == 'unipolrental' && userService.getCurrentUser().role != 'CLIENTE'  && userService.getCurrentUser().role != 'MANDANTE' "
                        type="button" mat-raised-button class="btn btn-info" (click)="setShowTariffe()">Visualizza
                        Tariffe
                      </button><br>
                    </div>



                    <app-adz-viewer (onCboxClicked)="onCboxClicked()"
                      *ngIf="item?.adz_data?.adz && selectedTab_name=='Doc Perizia'" [setValue]="item?.adz_data"
                      [cboxEnabled]="type == 'unipolrental' 
                                      && userService.getCurrentUser().role != 'CLIENTE'  
                                      && userService.getCurrentUser().role != 'MANDANTE'
                                      && userService.getCurrentUser().role != 'ISPETTORE'
                                    " [showOnlySelected]="type == 'unipolrental' && (
                                            ( userService.getCurrentUser().role == 'CLIENTE' && userService.getCurrentUser().email == 'r.baldessarro@unipolrental.it'  ) ||
                                            userService.getCurrentUser().role == 'MANDANTE')
                                          "
                      [disabled]="!permission?.permitted || !userCompetence || item.stato == 'CONCLUSO'"
                      (setValoreRiaddebito)="setValoreRiaddebito($event)">
                    </app-adz-viewer>
                    <!--
                    <mat-slide-toggle formControlName="fotoComplete" [(ngModel)]="item.fotoComplete">Analisi fotografica completa</mat-slide-toggle>
                    -->
                  </div>



                  <div class="col card" style="min-height: 60vh">

                    <div
                      style="position:absolute; top:0; left:0; right:0; bottom:0; background-color:white; padding: 15px; z-index:999;"
                      *ngIf="showSinistri">

                      <div class="row">
                        <div class="col-12" *ngFor="let e of sinistri_unipolrental">

                          <div class="card">

                            <div class="card-body">

                              <div class="row">
                                <div class="col" style="text-align: center;">
                                  <span style="font-weight: bold;">N° Contratto:</span><br>{{e.CodiceContratto}}
                                </div>
                                <div class="col" style="text-align: center;">
                                  <span style="font-weight: bold;">N° Sinistro:</span><br>{{e.NumeroSinistro}}
                                </div>

                                <div class="col" style="text-align: center;">
                                  <span style="font-weight: bold;">Data Ins.:</span><br>{{e.DataInserimentoSinistro}}
                                </div>
                                <div class="col" style="text-align: center;">
                                  <span style="font-weight: bold;">Tipo:</span><br>{{e.TipoSinistro}}
                                </div>
                                <div class="col" style="text-align: center;">
                                  <span style="font-weight: bold;">Data SX:</span><br>{{e.DataSinistro}}
                                </div>
                              </div>



                              <hr>

                              {{e.Danni ? e.Danni : 'Nessun danno'}}

                            </div>


                          </div>

                        </div>
                      </div>

                    </div>

                    <div
                      style="position:absolute; top:0; left:0; right:0; bottom:0; background-color:white; padding: 15px; z-index:999;"
                      *ngIf="showTariffe">

                      <div class="row" style="margin-top: 2rem;">
                        <div class="col-12">

                          <div class="card">
                            <div class="card-header card-header-tariffe">
                              {{ itemTariffe.descr }}
                            </div>
                            <div class="card-body">

                              <div class="row">
                                <div class="col-6">
                                  <span style="font-weight: bold;">Doppie chiavi:</span>
                                </div>
                                <div class="col-6" style="text-align: right;">
                                  <span>{{ itemTariffe.doppie_chiavi }} &euro;</span>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-6">
                                  <span style="font-weight: bold;">Danneggiamento targa:</span>
                                </div>
                                <div class="col-6" style="text-align: right;">
                                  <span>{{ itemTariffe.danneggiamento_targa }} &euro;</span>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-6">
                                  <span style="font-weight: bold;">Smarrimnento documenti:</span>
                                </div>
                                <div class="col-6" style="text-align: right;">
                                  <span>{{ itemTariffe.smarrimnento_documenti }} &euro;</span>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-6">
                                  <span style="font-weight: bold;">Auto non lavata esterno:</span>
                                </div>
                                <div class="col-6" style="text-align: right;">
                                  <span>{{ itemTariffe.auto_non_lavata_esterno }} &euro;</span>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-6">
                                  <span style="font-weight: bold;">Auto non lavata esterno e interno:</span>
                                </div>
                                <div class="col-6" style="text-align: right;">
                                  <span>{{ itemTariffe.auto_non_lavata_esterno_e_interno }} &euro;</span>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <app-gallery-result [(setSource)]="item" (onSelected)=openViewerResult($event,null)
                      [setRole]="userService.getCurrentUser().role" [(set360)]="img_360" style="height: 100%;"
                      (onAdd)="attachmentSave($event)" id="result" (onDelete)="saveResult(); save(false);"
                      [disabled]="!permission?.tabs[5] || !permission?.permitted || !userCompetence || userService.getCurrentUser().role == 'CLIENTE'"
                      [hideUploader]="true"
                      [showPP]="+item.cliente != 7  || userService.getCurrentUser().role == 'ADMIN' || (+item.cliente == 7 && userService.getCurrentUser().role == 'CLIENTE' &&  userService.getCurrentUser().email == item.creatoDa )"
                      *ngIf="item && selectedTab_name=='Doc Perizia'" [(blur)]="blur"
                      (onOpenViewer)="openViewerResult($event)" [orderMode]="orderMode">
                    </app-gallery-result>

                    <br>

                    <div class="row">
                      <!--
                      <div class="col-2">
                        <button type="button" mat-raised-button class="btn btn-success"
                          (click)="addPrefix('F')"
                          *ngIf="!orderMode && userService.getCurrentUser().role == 'ADMIN' || userService.getCurrentUser().role == 'GESTORE' && item.stato =='CONSEGNATO' ">
                          AGGIUNGI PREFISSO
                        </button>
                      </div>
                      -->
                      <div class="col">
                        <button type="button" mat-raised-button class="btn btn-success" (click)="toggleOrderMode()"
                          style="width:100%"
                          *ngIf="!orderMode && (userService.getCurrentUser().role == 'ADMIN' || userService.getCurrentUser().role == 'GESTORE' || userService.getCurrentUser().role == 'SALACONTROLLO' || userService.getCurrentUser().role == 'ISPETTORE')  ">
                          MODALITà ORDINAMENTO
                        </button>
                      </div>
                      <div class="col">
                        <button type="button" mat-raised-button class="btn btn-danger" (click)="delAttachments()"
                          style="width:100%"
                          *ngIf="!orderMode && (userService.getCurrentUser().role == 'ADMIN' || userService.getCurrentUser().role == 'GESTORE' ) && item.stato!='CONCLUSO'  ">
                          CANCELLA TUTTI GLI ALLEGATI
                        </button>
                      </div>
                      <div class="col">
                        <button type="button" mat-raised-button class="btn btn-success" (click)="setBlur()"
                          style="width:100%" *ngIf="!orderMode && (userService.getCurrentUser().role == 'ADMIN' )  ">
                          RIMUOVI SFONDO
                        </button>
                      </div>
                    </div>


                    <div class="row">
                      <div class="col-2">
                        <button type="button" mat-raised-button class="btn btn-success" (click)="saveOrder()"
                          *ngIf="orderMode && (userService.getCurrentUser().role == 'ADMIN' || userService.getCurrentUser().role == 'GESTORE' || userService.getCurrentUser().role == 'SALACONTROLLO' || userService.getCurrentUser().role == 'ISPETTORE') && item.stato !='CONCLUSO' ">
                          SALVA ORDINAMENTO
                        </button>
                      </div>
                      <div class="col-2">
                        <button type="button" mat-raised-button class="btn btn-danger" (click)="cancelOrder()"
                          *ngIf="orderMode && (userService.getCurrentUser().role == 'ADMIN' || userService.getCurrentUser().role == 'GESTORE' || userService.getCurrentUser().role == 'SALACONTROLLO' || userService.getCurrentUser().role == 'ISPETTORE') && item.stato !='CONCLUSO' ">
                          ANNULLA ORDINAMENTO
                        </button>
                      </div>
                    </div>

                  </div>

                </div>


              </mat-tab>

              <mat-tab label="Privacy" *ngIf="item?.result?.privacy"><br>

                <ng-template matTabLabel>
                  <span [matBadge]="item?.result?.privacy?.length" matBadgeOverlap="false">Privacy</span>
                </ng-template>

                <!-- <ng2-smart-table class="grid" [settings]="privacy_settings" [source]="privacy_source">
                </ng2-smart-table> -->

              </mat-tab>

              <mat-tab label="Proposte"
                *ngIf="!(!(permission?.tabs[5] || ( item.stato == 'CONCLUSO' || item.stato == 'CONFERMATO' ))) && type=='perizie'">
                <br>

                <ng-template matTabLabel>
                  <span [matBadge]="item?.proposte?.length" matBadgeOverlap="false">Proposte</span>
                </ng-template>




                <div class="row">
                  <div class="col-4">

                    <mat-form-field class="full">
                      <mat-label>Importo concordato</mat-label>
                      <input type="number" matInput placeholder="Importo concordato"
                        formControlName="importo_concordato" [(ngModel)]="item.importo_concordato">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Email danneggiato</mat-label>
                      <input [readonly]="permission?.tabs[5] == 2" type="email" matInput placeholder="Email"
                        formControlName="riparatore_email" [(ngModel)]="item.danneggiato_email">
                    </mat-form-field>

                    <button type="button" mat-raised-button class="btn btn-success" style="width:100%"
                      (click)="sendConcordato('d')" [disabled]="!item?.importo_concordato>0">Invia concordato (al
                      danneggiato)</button>
                    <br>
                    <mat-form-field class="full">
                      <mat-label>Email riparatore</mat-label>
                      <input [readonly]="permission?.tabs[5] == 2" type="email" matInput placeholder="Email"
                        formControlName="riparatore_email" [(ngModel)]="item.riparatore_email">
                    </mat-form-field>

                    <button type="button" mat-raised-button class="btn btn-success" style="width:100%"
                      (click)="sendConcordato('r')" [disabled]="!item?.importo_concordato>0">Invia concordato (al
                      riaratore)</button>

                    <input type="file" #concordato_file id="concordato_file" (change)="getFileConcordato($event)"
                      style="display: none;">




                  </div>
                  <div class="col">

                    <!-- <ng2-smart-table class="grid" [settings]="concordatix_settings" [source]="concordatix_source">
                    </ng2-smart-table> -->

                  </div>
                </div>








                <!--
                <div class="row" *ngIf="false">
                  <div class="col-4">

                    <mat-form-field class="full">
                      <mat-label >Importo proposta</mat-label>
                      <input type="number" matInput placeholder="Importo proposta" formControlName="importo_proposta"
                        [(ngModel)]="item.importo_proposta">
                    </mat-form-field>


                    <mat-form-field class="full">
                      <mat-label>Danneggiato</mat-label>
                      <input [readonly]="permission?.tabs[5] == 2" type="text" matInput placeholder="Nominativo"
                        formControlName="danneggiato_nome" [(ngModel)]="item.danneggiato_nome">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Telefono</mat-label>
                      <input [readonly]="permission?.tabs[5] == 2" type="text" matInput placeholder="Telefono"
                        formControlName="danneggiato_telefono" [(ngModel)]="item.danneggiato_telefono">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Email</mat-label>
                      <input [readonly]="permission?.tabs[5] == 2" type="email" matInput placeholder="Email"
                        formControlName="danneggiato_email" [(ngModel)]="item.danneggiato_email">
                    </mat-form-field>

                    <p style="font-size:120%; font-weight: bold; border: 1px solid gray; padding: 30px"
                      *ngIf="item?.iban_proposta">IBAN: {{item.iban_proposta}}</p>


                    <button type="button" mat-raised-button class="btn btn-success" style="float:right"
                      (click)="sendSMSProposta()" [disabled]="!item?.importo_proposta>0">Invia proposta via SMS</button>

                  </div>
                  <div class="col">


         <!--             <ng2-smart-table class="grid" [settings]="proposte_settings" [source]="proposte_source">
                    </ng2-smart-table>

                  </div>
                </div>
              -->

                <!--
                <div class="row">
                  <div class="col-4">

                    <mat-form-field class="full">
                      <mat-label >Importo concordato</mat-label>
                      <input type="number" matInput placeholder="Importo concordato"
                        formControlName="importo_concordato" [(ngModel)]="item.importo_concordato">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Riparatore</mat-label>
                      <input [readonly]="permission?.tabs[5] == 2" type="text" matInput placeholder="Riparatore"
                        formControlName="riparatore_nome" [(ngModel)]="item.riparatore_nome">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Telefono</mat-label>
                      <input [readonly]="permission?.tabs[5] == 2" type="text" matInput placeholder="Telefono"
                        formControlName="riparatore_tel" [(ngModel)]="item.riparatore_tel">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Email</mat-label>
                      <input [readonly]="permission?.tabs[5] == 2" type="email" matInput placeholder="Email"
                        formControlName="riparatore_email" [(ngModel)]="item.riparatore_email">
                    </mat-form-field>

                    <button type="button" mat-raised-button class="btn btn-success" style="float:right"
                      (click)="sendConcordato()" [disabled]="!item?.importo_proposta>0">Invia concordato via
                      E-mail</button>

                  </div>
                  <div class="col">


            <!--          <ng2-smart-table class="grid" [settings]="concordati_settings" [source]="concordati_source">
                    </ng2-smart-table>

                  </div>
                </div>
              -->
              </mat-tab>

              <mat-tab label="Moduli"
                *ngIf=" item?.modulo1 &&  !(!(permission?.tabs[5] || ( item.stato == 'CONCLUSO' || item.stato == 'CONFERMATO' )))">
                <br>

                <div class="row">

                  <div class="col-12" style="padding-left:20px; padding-right:20px;">

                    <div class="row">
                      <div class="col-12 ">
                        <div class="row">

                          <div *ngFor="let i of [1,2,3,4,5]">
                            <div class="col-2" *ngIf="item['modulo'+i]">
                              <button type="button" mat-raised-button class="btn "
                                [ngClass]="{'btn-success': tab_mod == i}"
                                (click)="tab_mod=i">{{item['modulo'+i+'_descr']}}</button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>


                    <div *ngFor="let i of [1,2,3,4,5]" class="row">
                      <div style="padding:10px" class="col-12" *ngIf="tab_mod==i && item['modulo'+i] ">
                        <!-- [disabled]="document['competence'+i] != role"-->
                        <!-- {{item['modulo'+i]}} - {{item['formVal'+i]}} -->

                        <app-form [(form)]="item['modulo'+i]" [(result)]="item['formVal'+i]"></app-form>

                        <button type="button" class="btn btn-success" (click)="saveModule()">Salva</button>
                        <!-- [disabled]="item['competence'+i] != role"-->
                      </div>
                    </div>

                  </div>
                </div>

              </mat-tab>

              <mat-tab label="Messaggi" *ngIf="permission?.tabs[6]"><br>

                <div class="row" *ngIf="selectedTab_name=='Messaggi'">

                  <div class="col-sm-6 card" *ngIf="permission?.chats[0]">
                    <div class="card-header card-header-info">
                      <h5 class="card-title">Messaggi Ispettore</h5>
                      <p class="card-category">{{item.assegnatoA2}}</p>
                    </div>

                    <ngx-chat [recipient]="item.assegnatoA2" [code]="'perizie-' + item.id"
                      [readonly]="permission?.tabs[6] == 2"></ngx-chat>

                  </div>

                  <div class="col-sm-6 card" *ngIf="permission?.chats[1] && item.assegnatoA1 && item.assegnatoA1!=''">
                    <div class="card-header card-header-success">
                      <h5 class="card-title">Messaggi Sala Controllo</h5>
                      <p class="card-category">{{item.assegnatoA1}}</p>
                    </div>

                    <ngx-chat [recipient]="item.assegnatoA1" [code]="'perizie-' + item.id"
                      [readonly]="permission?.tabs[6] == 2"></ngx-chat>

                  </div>

                  <div class="col-sm-6 card"
                    *ngIf="permission?.chats[2] && item.creatoDa != userService.getCurrentUser().email ">
                    <div class="card-header card-header-danger">
                      <h5 class="card-title">Messaggi Cliente</h5>
                      <p class="card-category">{{item.creatoDa}}</p>
                    </div>

                    <ngx-chat [recipient]="item.creatoDa" [code]="'perizie-' + item.id"
                      [readonly]="permission?.tabs[6] == 2"></ngx-chat>

                  </div>

                  <div class="col-sm-6 card"
                    *ngIf="permission?.chats[3] || ( userService.getCurrentUser().role == 'ISPETTORE' && ( !item.assegnatoA1  || item.assegnatoA1 =='')  ) ">
                    <div class="card-header card-header-warning">
                      <h5 class="card-title">Messaggi Gestore</h5>
                      <p class="card-category">Gestore</p>
                    </div>

                    <ngx-chat
                      [recipient]="(userService.getCurrentUser().role == 'ISPETTORE' || userService.getCurrentUser().role == 'SALACONTROLLO' )  ? 'GESTORE' : userService.getCurrentUser().email"
                      [code]="'perizie-' + item.id" [readonly]="permission?.tabs[6] == 2"></ngx-chat>

                  </div>

                </div>

              </mat-tab>

              <mat-tab label="Note Int." *ngIf="permission?.tabs[10]"> <br>
                <ng-template matTabLabel>
                  <span [matBadge]="reminders?.length" matBadgeOverlap="false"
                    [matBadgeColor]="isURAutorized() ? 'accent' : 'primary'">Note Int.</span>
                </ng-template>

                <br>


                <div class="row">
                  <div class="col" *ngFor="let t of [ {title:'Note Interne', code:'reminder'}]">

                    <div class="card" style="min-height: 300px;">
                      <div class="card-header card-header-info">
                        <h4 class="card-title">
                          {{t.title}}
                          <div style="float:right"> </div>
                        </h4>
                        <eco-fab-speed-dial *ngIf="id!=0"
                          style="z-index: 999; position:absolute; right:15px; bottom:15px;" animationMode="fling">
                          <eco-fab-speed-dial-trigger>
                            <button type="button" mat-fab color="warn" (click)="addDett()">
                              <mat-icon>add</mat-icon>
                            </button>
                          </eco-fab-speed-dial-trigger>
                        </eco-fab-speed-dial>
                      </div>
                      <div class="card-body">

                        <div class="card" *ngFor="let i of reminders " style="padding:5px!important">
                          <p style="width: 90%;">{{i.categoria}} <span *ngIf="i.categoria1">- {{i.categoria1}}</span> -
                            {{i.descrizione}}
                          </p>
                          <p style="text-align: right;">
                            <span style="float:left; color:red" *ngIf="i.dtaReminder">
                              <i style="float:left" class="material-icons"> access_alarm </i>
                              &nbsp;{{i.dtaReminder|date:'dd/MM/yyyy HH:mm'}}&nbsp;
                              <i style="float:right" class="material-icons" *ngIf="i.dtaSent"> email </i>
                            </span>
                            {{i.creatoDaDescr}},
                            {{i.dta|date:'dd/MM/yyyy HH:mm'}}
                          </p>

                          <button *ngIf="userService.getCurrentUser().role == 'ADMIN'" type="button" color="warn"
                            mat-icon-button (click)="deleteReminder(i.id)"
                            style="position:absolute; top:0; right:20px;"><i
                              class="material-icons">delete_forever</i></button>

                        </div>

                      </div>
                    </div>



                  </div>

                </div>


              </mat-tab>

              <mat-tab label="Attività" *ngIf="permission?.tabs[7]"> <br>


                <ng-template matTabLabel>
                  <span [matBadge]="getAttivita()?.length" matBadgeOverlap="false">Attività</span>
                </ng-template>


                <div class="row">

                  <div *ngFor="let i of getAttivita()" class="card col-2" style="margin-left:0; height: fit-content;">

                    <button *ngIf="userService.getCurrentUser().role == 'ADMIN' && i.sts == 'EXPORTED FTP'"
                      type="button" color="warn" mat-icon-button (click)="deleteAttivita(i.ID)"
                      style="position:absolute; top:0; right:20px;"><i
                        class="material-icons">delete_forever</i></button>


                    <img [src]="getIconUrlFromStatus(i.sts)" style="width:80px;height:80px; margin:auto; display:block"
                      class="shadowed growx">
                    <hr>

                    <div style="text-align:center;">
                      <div style="margin-bottom:5px;"> {{i.sts}}</div>
                      <hr>

                      <div style="margin-bottom:5px;">
                        Gestito da:<br>
                        <span *ngIf="i.nome"><strong>{{i.nome}}&nbsp;{{i.cognome}}</strong></span>
                        <span *ngIf="!i.nome"><strong>ENGINE</strong></span>
                      </div>
                      <hr>

                      <div style="margin-bottom:5px;">
                        <strong>{{i.dta | date: 'dd/MM/yyyy HH:mm:ss'}}</strong>
                      </div>

                    </div>



                  </div>

                </div>

              </mat-tab>

              <mat-tab label="Email" *ngIf="permission?.tabs[11]"> <br>
                <ng-template matTabLabel>
                  <span [matBadge]="email_source?.length" matBadgeOverlap="false">Email</span>
                </ng-template>

                <!-- <ng2-smart-table class="grid" [settings]="logs_settings" [source]="logs_source">
                </ng2-smart-table> -->


                <mat-expansion-panel *ngFor="let e of email_source">
                  <mat-expansion-panel-header>
                    <mat-panel-title style="font-weight: bold;">
                      <span *ngIf="e.isError"><i class="material-icons" style="color:#e50000">error</i>&nbsp;&nbsp;</span>
                      {{e.subject}} </mat-panel-title>
                    <mat-panel-description>
                      <span style="font-weight: bold;">Del:</span> {{e.creationDateTz|date:'dd/MM/yyyy
                      HH:mm'}}&nbsp;-&nbsp;
                      <span style="font-weight: bold;">Inviato il:</span>{{e.sentDateTz|date:'dd/MM/yyyy HH:mm'}}
                    </mat-panel-description>

                  </mat-expansion-panel-header>
                  <span *ngIf="e.send">
                    <span style="font-weight: bold;">A:</span> {{e.recipientEmail}};
                    <span style="font-weight: bold;">CC:</span> {{e.cc}}<br><br>

                    <span [innerHTML]='e.text?.replaceAll("\n","<br>")'></span>
                  </span>
                  <span *ngIf="!e.send">
                    <span style="font-weight: bold;">DA:</span>
                    {{e.from}}<br><br>

                    <span [innerHTML]='e.body?.replaceAll("\n","<br>")'></span>
                  </span>
                </mat-expansion-panel>
                <!--
                <div *ngFor="let e of email_source" class="card card-body">
                  <h5 style="font-weight: bold;">{{e.subject}}
                    <span style="float:right; font-size: 90%;">
                      Del: <span style="font-weight: normal;">{{e.creationDateTz|date:'dd/MM/yyyy HH:mm'}}</span> - 
                      Inviato il: <span style="font-weight: normal;">{{e.sentDateTz|date:'dd/MM/yyyy HH:mm'}}</span>
                    </span>
                  </h5>
                  <span>
                    <span style="font-weight: bold;">A:</span> {{e.recipientEmail}}; 
                    <span style="font-weight: bold;">CC:</span> {{e.cc}}<br><br>

                    <span [innerHTML]='e.text?.replaceAll("\n","<br>")'></span>
                  </span>
                </div>
-->
                <!--
                <ag-grid-angular style="width: 100%; height: calc(100vh - 350px);" class="ag-theme-alpine"
                  [rowData]="email_source" [columnDefs]="email_settings.columnDefs" [defaultColDef]="defaultColDef">
                </ag-grid-angular>
-->
              </mat-tab>

              <mat-tab label="Log" *ngIf="permission?.tabs[8]"> <br>

                <!-- <ng2-smart-table class="grid" [settings]="logs_settings" [source]="logs_source">
                </ng2-smart-table> -->

                <ag-grid-angular style="width: 100%; height: calc(100vh - 350px);" class="ag-theme-alpine"
                  [rowData]="logs_source" [columnDefs]="logs_settings.columnDefs" [defaultColDef]="defaultColDef">
                </ag-grid-angular>

              </mat-tab>

            </mat-tab-group>


          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<app-doc-viewer [index]="docviewer_i" [(files)]="docviewer_source" *ngIf="docviewer" (closed)="closeDocviewer()"
  [position]="docviewer_position" [filter]="docviewer_filter" [doubleView]="docviewer_double" [descr]="docviewer_descr">
</app-doc-viewer>


<div class="dialog animated heartBeat" *ngIf="showAddAdz">
  <a style="float:right" (click)="toggleAddAdz()"><i class="material-icons">close</i></a>

  <app-gallery [(source)]="item.allegati" (onAdd)="attachmentSave($event)" [uploader]="true" id="allegati"
    [dropzone]="false" [disabled]="!permission?.tabs[5]">
  </app-gallery>
</div>


<div class="dialog animated heartBeat" *ngIf="forecast">
  <a style="float:right" (click)="forecast=undefined"><i class="material-icons">close</i></a>

  <app-weather [data]="forecast" *ngIf="forecast"></app-weather>
</div>


<div class="dialog animated heartBeat" *ngIf="legend">
  <a style="float:right" (click)="legend=!legend"><i class="material-icons">close</i></a>

  <table>
    <tr>
      <td class="before-date" style="border: 1px solid lightgrey;  width:80px!important; "></td>
      <td>&nbsp;DATA NON VALIDA</td>
    </tr>
    <tr>
      <td></td>
    </tr>
    <tr>
      <td class="disable-date" style="border: 1px solid lightgrey;  width:80px!important; "></td>
      <td>&nbsp;CHIUSO</td>
    </tr>
    <tr>
      <td></td>
    </tr>
    <tr>
      <td class="holiday-date" style="border: 1px solid lightgrey;  width:80px!important; "></td>
      <td>&nbsp;FESTIVITA'</td>
    </tr>
    <tr>
      <td></td>
    </tr>
    <tr>
      <td class="oncall-date" style="border: 1px solid lightgrey;  width:80px!important; ">&nbsp;</td>
      <td>&nbsp;RICHIESTA PRENOTAZIONE</td>
    </tr>
    <tr>
      <td></td>
    </tr>
    <tr>
      <td class="scad-date" style="border: 1px solid lightgrey;  width:80px!important; ">&nbsp;</td>
      <td>&nbsp;CONTRATTO NON SCADUTO</td>
    </tr>
  </table>
</div>


<div class="dialog-large animated heartBeat" *ngIf="showReclamo">

  <a style="float:right" (click)="toggleReclamo()"><i class="material-icons">close</i></a>

  <app-reclami [id]="item.id" [role]="userService.getCurrentUser().role">
  </app-reclami>

</div>

<div class="dialog-large animated heartBeat" *ngIf="showElencoStessaTarga">

  <a style="float:right" (click)="toggleElencoStessaTarga()"><i class="material-icons">close</i></a>

  <div class="row">

    <div class="col-4" *ngFor="let e of elenco_perizie_stessa_targa">

      <div style="border: 1px gray solid; border-radius: 15px; margin: 5px; padding: 15px">

        <div>ID: <div style="width:100%; text-align:right">{{e.id}}</div>
        </div>
        <div>Targa: <div style="width:100%; text-align:right">{{e.targa}}</div>
        </div>
        <div>Tipo: <div style="width:100%; text-align:right">{{e.tipo}}</div>
        </div>
        <div>Data: <div style="width:100%; text-align:right">{{e.dtaStart | date}}</div>
        </div>
        <div>Stato: <div style="width:100%; text-align:right">{{e.stato}}</div>
        </div>

        <div class="row">

          <div class="col-6">
            <button type="button" class="btn" (click)="openOtherTab(e.id)" style="width: 100%;"
              *ngIf="userService.getCurrentUser().role == 'ADMIN' || userService.getCurrentUser().role == 'GESTORE'">
              Apri
            </button>
          </div>

          <div class="col-6">
            <button type="button" class="btn" (click)="openOtherPdf(e)" style="width: 100%;">
              Apri PDF
            </button>
          </div>

        </div>
      </div>

    </div>

  </div>



</div>

<div class="card dialog-large animated heartBeat" *ngIf="adzResult" style="width:initial; top:15%!important">

  <div class="card-header card-header-info">
    <h4 class="card-title">
      Report di caricamento ADZ
      <a style="float:right" (click)="adzResult=undefined"><i class="material-icons">close</i></a>
    </h4>
  </div>
  <div class="card-body">
    <p *ngFor="let e of adzResult" style="border-bottom: dashed 1px gray;">
      <i *ngIf="e.status=='OK'" style="float:right; color:green" class="material-icons">done</i>
      <i *ngIf="e.status!='OK'" style="float:right; color:red" class="material-icons">warning</i>
      {{e.descr}}
    </p>

    <div *ngIf="isAdzResultError()">
      <h4 style="color:red">Caricamento annullato</h4>
    </div>

    <div *ngIf="loading_percentage">
      <br>
      <h3 style="text-align: center;">{{ loading_percentage == 100 ? 'Salvataggio in corso' :'Caricamento in corso...'
        }}</h3>
      <mat-progress-bar *ngIf="loading_percentage > 0" [value]="loading_percentage"
        [mode]="loading_percentage == 100 ? 'indeterminate' : 'determinate'"> </mat-progress-bar>
      <h3 style="text-align: center;">

        {{loading_loaded/1024/1024 | number:'.1-1'}} / {{loading_total/1024/1024 | number:'.1-1'}}MB
        -
        {{loading_percentage | number: '.0-0'}}%
      </h3>
    </div>
  </div>


</div>