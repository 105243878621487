import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public _rowDataSubject = new BehaviorSubject<any[]>([]);
  rowData$ = this._rowDataSubject.asObservable();

  public toggleSubject = new BehaviorSubject(null);
  toggle$ = this.toggleSubject.asObservable();

  setRowData(rowData: any[]) {
    this._rowDataSubject.next(rowData);
  }

  toggleSidebar() {
    this.toggleSubject.next(true);
  }

  constructor() { }
}
