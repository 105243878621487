import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

import * as moment from 'moment-timezone';

@Component({
  selector: 'app-grid-edit',
  templateUrl: './grid-edit.component.html',
  styleUrls: ['./grid-edit.component.scss']
})
export class GridEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  params: any;
  agInit(params: any) {
    this.params = params;
  }
  btnClickedHandler(e) {
    this.params.clicked(this.params.data);
  }

}

@Component({
  selector: 'app-grid-duplicate',
  template: `<i class="material-icons" (click)="btnClickedHandler()" >file_copy</i>`

})
export class GridDuplicateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  params: any;
  agInit(params: any) {
    this.params = params;
  }
  btnClickedHandler() {
    this.params.clicked(this.params.data);
  }

}

@Component({
  selector: 'app-grid-rotate',
  template: `<i style="width: 25px !important; cursor: pointer;" class="fa fa-undo" (click)="btnClickedHandler()"></i>`

})
export class GridRotateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  params: any;
  agInit(params: any) {
    this.params = params;
  }

  btnClickedHandler() {
    this.params.clicked(this.params.data);
  }

}




@Component({
  selector: 'app-grid-updown',
  template: `
    <i class="material-icons" (click)="btnClickedHandler('up')" >arrow_upward</i>
    <i class="material-icons" (click)="btnClickedHandler('down')" >arrow_downward</i>
  `,
  styleUrls: ['./grid-edit.component.scss']
})
export class GridUpDownComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  params: any;
  agInit(params: any) {
    this.params = params;
  }
  btnClickedHandler(d) {
    this.params.clicked({ direction: d, order: this.params.value });
  }

}
@Component({
  selector: 'app-grid-checkmark',
  template: `
   <div style="display:flex; align-items: center; height: 100%"> <i class="material-icons" (click)="btnClickedHandler('up')" >launch</i></div>

  `,
  styleUrls: ['./grid-edit.component.scss']
})
export class GridCheckmarkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  params: any;
  agInit(params: any) {
    this.params = params;
  }
  btnClickedHandler(d) {
    this.params.clicked({ direction: d, order: this.params.value });
  }

}

@Component({
  selector: 'app-grid-delete',
  template: '<div style="display:flex; align-items: center; height: 100%"><i class="material-icons" (click)="btnClickedHandler($event)" >delete</i></div>',
  styleUrls: ['./grid-edit.component.scss']
})
export class GridDeleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  params: any;
  agInit(params: any) {
    this.params = params;
  }
  btnClickedHandler(e) {
    this.params.clicked(this.params.value);
  }

}



@Component({
  selector: 'app-grid-icon',
  template: `<div style="display:flex; align-items: center; height: 100%">
  <i class="material-icons" (click)="btnClickedHandler($event)" >{{icon}}</i>
  </div>`,
  styleUrls: ['./grid-edit.component.scss']
})
export class GridIconComponent implements OnInit {
  icon = '';
  constructor() { }

  ngOnInit(): void {
  }
  params: any;
  agInit(params: any) {
    this.params = params;
    if (this.params.data.completed > 0) {
      this.icon = 'collections'
    }
  }
  btnClickedHandler(e) {
    this.params.clicked(this.params);
  }

}

@Component({
  selector: 'app-grid-icon',
  template: `<i class="material-icons" (click)="btnClickedHandler($event)" >
    {{ this.params.data.adz_data ? 'cloud_download' : '' }}
  </i>`,
  styleUrls: ['./grid-edit.component.scss']
})
export class GridDownloadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  params: any;

  agInit(params: any) {
    this.params = params;
  }
  btnClickedHandler(e) {
    this.params.clicked(this.params);
  }

}



@Component({
  selector: 'app-flotta-icon',
  template: `
  <i *ngIf="icon == 'check'" class="material-icons" style="margin-top:5px; color:green">checkbox-marked-circle</i>  
  <i *ngIf="icon == 'warn' " class="material-icons" style="margin-top:5px; color:orange">report-problem</i>`,
  styleUrls: ['./grid-edit.component.scss']
})
export class GridFlottaComponent implements ICellRendererAngularComp {

  icon

  constructor() { }


  params: ICellRendererParams;
  agInit(params) {

    // console.log(params)

    if (+params.data.idPerizia > 0) return


    let dScadenza = moment(params.data.ScadenzaContrattoPrevista, 'DDMMYYYY').format('YYYYMMDD')
    let dNow = moment().format('YYYYMMDD')

    if (dScadenza > dNow) {
      this.icon = 'warn'
    } else {
      this.icon = 'check'
    }


  }

  refresh(params): boolean {
    return true;
  }
}


@Component({
  selector: 'app-auth-icon',
  template: `<div class="btn-integrazioni"><button class="button-auth" style="cursor: pointer" [disabled]="params.data.stato != 'RICHIESTO'" (click)="btnClickedHandler($event)"><i class="material-icons"> check </i></button></div>`,
  styleUrls: ['./grid-edit.component.scss']
})
export class GridAuthorizeComponent implements ICellRendererAngularComp {

  icon

  constructor() { }

  params;

  agInit(params) {
    this.params = params;
  }

  refresh(params): boolean {
    return true;
  }

  btnClickedHandler(e) {
    if (this.params.data.stato == 'RICHIESTO') {
      this.params.clicked(this.params.data);
    }
  }
}

/* params.data.stato == 'RICHIESTO' */
@Component({
  selector: 'app-refuse-icon',
  template: `<div class="btn-integrazioni"><button class="button-ref" [disabled]="params.data.stato != 'RICHIESTO'" style="cursor: pointer" (click)="btnClickedHandler($event)"><i class="material-icons"> close </i></button></div>`,
  styleUrls: ['./grid-edit.component.scss']
})
export class GridRefuseComponent implements ICellRendererAngularComp {

  icon

  constructor() { }

  params;

  agInit(params) {
    this.params = params;
    //console.log(params)
  }

  btnClickedHandler(e) {
    if (this.params.data.stato == 'RICHIESTO') {
      this.params.clicked(this.params.data);
    }
  }

  refresh(params): boolean {
    return true;
  }
}

@Component({
  selector: 'app-com-icon',
  template:
    `<div class="com-icons" style="display: flex">
    <div class="whatsapp-r"><button class="btn-notifiche" [disabled]="params.data.method_whatsapp != 1"><i class="fa fa-whatsapp icon"></i></button></div>
    <div class="sms-r"><button class="btn-notifiche" [disabled]="params.data.method_sms != 1"><i class="fa fa-commenting-o icon"></i></button></div>
    <div class="email-r"><button class="btn-notifiche" [disabled]="params.data.method_email != 1"><i class="fa fa-envelope icon"></i></button></div>
    <div class="pec-r"><button class="btn-notifiche" [disabled]="params.data.method_pec != 1"><i class="fa fa-shield icon"></i></button></div>
    <div class="voce-r"><button class="btn-notifiche" [disabled]="params.data.method_voicecall != 1"><i class="fa fa-phone icon"></i></button></div>
    </div>`,

  styleUrls: ['./grid-edit.component.scss']
})
export class GridComunicationIconComponent implements ICellRendererAngularComp {

  value

  constructor() { }

  params;

  agInit(params) {
    this.params = params;

  }

  refresh(params): boolean {
    return true;
  }

}

@Component({
  selector: 'app-alarm-icon',
  template:
    `<div class="bell-danger"><button class="btn-notifiche" data-title="{{ 'Scaduta il: ' + data }}" [disabled]="!isDataScaduta"><i class="fa fa-bell icon"></i></button></div>
     <div class="bell-warning"><button class="btn-notifiche" data-title="{{ 'Scadenza il: ' + data }}" [disabled]="!isDataInScadenza"><i class="fa fa-bell icon" style="color:#FFBE60"></i></button></div>`,
  styleUrls: ['./grid-edit.component.scss']
})
export class GridAlarmIconComponent implements ICellRendererAngularComp {

  value
  isDataScaduta;
  isDataInScadenza;
  data;

  constructor() { }

  params;

  agInit(params) {
    this.params = params;
    this.data = moment(params.data.checkOutPrevista).format('YYYY-MM-DD');

    if ((moment(new Date()).format('YYYY-MM-DD') == moment(params.data.checkOutPrevista).format('YYYY-MM-DD')) || moment(params.data.checkOutPrevista).isBefore(moment(new Date()))) {
      this.isDataScaduta = true;
    }
    if (moment(new Date()).add(1, 'day').format('YYYY-MM-DD') == moment(params.data.checkOutPrevista).format('YYYY-MM-DD')) {
      this.isDataInScadenza = true;
    }
  }

  refresh(params): boolean {
    return true;
  }

  /* btnClickedHandler(e) {
    if (this.params.data.stato == 'RICHIESTO') {
    this.params.clicked(this.params.data);
    }
  } */
}

@Component({
  selector: 'app-exclamation-icon',
  template:
    `<div class="exclamation-danger"><button class="btn-notifiche" data-title="" [disabled]="params.data.richiestaAutorizzazione == 0"><i class="fa fa-exclamation-circle icon"></i></button></div>`,
  styleUrls: ['./grid-edit.component.scss']
})
export class GridExclamationIconComponent implements ICellRendererAngularComp {

  constructor() { }

  params;

  agInit(params) {
    this.params = params;
  }

  refresh(params): boolean {
    return true;
  }

}


@Component({
  selector: 'app-grid-active',
  template: `
  
  <i class="material-icons" color="success" style="color:green" *ngIf="value">
    fiber_manual_record
  </i>

  <i class="material-icons" color="success" style="color:red" *ngIf="!value">
      fiber_manual_record
  </i>

  `,
  styleUrls: ['./grid-edit.component.scss']
})
export class GridActiveComponent implements OnInit {

  value = false

  constructor() { }

  ngOnInit(): void {



  }
  params: any;
  agInit(params: any) {
    this.params = params;
    this.value = this.params.value && +this.params.value > 0
  }
  btnClickedHandler(e) {
    this.params.clicked(this.params.value);
  }

}

@Component({
  selector: 'app-grid-active',
  template: `
  
  <i class="material-icons"  (click)="btnClickedHandler($event)" >
    analytics
  </i>



  `,
  styleUrls: ['./grid-edit.component.scss']
})
export class GridStatusComponent implements OnInit {

  value = false

  constructor() { }

  ngOnInit(): void {
  }
  params: any;
  agInit(params: any) {
    this.params = params;
  }
  btnClickedHandler(e) {
    this.params.clicked(this.params.value);
  }

}

@Component({
  selector: 'icon-renderer',
  template: `<div style="display:flex; align-items: center; heigth: 100%"><i class="material-icons" (click)="btnClickedHandler($event)">euro_symbol</i></div>`,
  styleUrls: ['./grid-edit.component.scss']
})
export class IconRendererEuro implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  params: any;
  agInit(params: any) {
    this.params = params;
  }
  btnClickedHandler(e) {
    this.params.clicked(this.params.data);
  }

}


@Component({
  selector: 'app-grid-okerr',
  template: `<i *ngIf="params.value" [ngStyle]="{'color': params.value ? 'green' : 'error'}" class="material-icons" (click)="btnClickedHandler($event)" >done-outline</i>`,
  styleUrls: ['./grid-edit.component.scss']
})
export class GridOKErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  params: any;
  agInit(params: any) {
    this.params = params;
  }
  btnClickedHandler(e) {
    this.params.clicked(this.params);
  }

}


@Component({
  selector: 'app-progress-bar-cell-renderer',
  template: `
    <div class="progress-bar-container">
      <div class="progress-bar" [style.width.%]="value"></div>
      <span class="progress-label">{{ value }}%</span>
    </div>
  `,
  styles: [`
    .progress-bar-container {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: #ccc;
      border-radius: 4px;
      overflow: hidden;
    }
    .progress-bar {
      height: 100%;
      background-color: #4caf50;
      transition: width 0.3s;
    }
    .progress-label {
      position: absolute;
      width: 100%;
      text-align: center;
      font-size: 12px;
      color: white;
      font-weight:bold;
      top: 0;
    }
  `]
})
export class ProgressBarCellRenderer implements ICellRendererAngularComp {
  value: number = 0;

  agInit(params: any): void {
    this.value = Math.round(params.value);
  }

  refresh(params: any): boolean {
    this.value = Math.round(params.value);
    return true;
  }
}