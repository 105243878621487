import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
/// <reference path="../../../../../../node_modules/bingmaps/types/MicrosoftMaps/Microsoft.Maps.All.d.ts" />

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss']
})
export class LocationSearchComponent implements OnInit {

  @Input() height = '24vh'

  @Input() location_lat
  @Input() location_lon

  @Output() onSelected = new EventEmitter<any>();

  @Input() showMap = true;


  @Input() disabled: boolean = false;

  locationSearch = '';

  //form_location_search: UntypedFormGroup;

  loc: any = {}

  id: string

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) {

    console.clear()

    this.id = Math.random().toString(36).substring(7);
/*
    this.form_location_search = this.formBuilder.group({
      locationSearch: ['',],
    })
*/
  }

  ngOnInit() {
    setTimeout(() => {
      this.autosuggest()
    }, 100);
  }


  map;
  autosuggest() {

    console.log('locationSearch autosuggest')

    if (!this.map) {

      let c = new Microsoft.Maps.Location(this.location_lat ? this.location_lat : 0, this.location_lon ? this.location_lon : 0)

      this.map = new Microsoft.Maps.Map(document.getElementById('map_' + this.id), {
        credentials: 'AvShsxmUDnD3rCAp_kwUxadhZRuRtKMXHGstTPBMEjAFYnY9ekRdDZKSkj96iYYm',
        center: c,
        zoom: 12
      });

      this.map.entities.clear();
      var pushpin = new Microsoft.Maps.Pushpin(c);
      this.map.entities.push(pushpin);

    }

    let x: Microsoft.Maps.IModuleOptions = {
      credentials: 'AvShsxmUDnD3rCAp_kwUxadhZRuRtKMXHGstTPBMEjAFYnY9ekRdDZKSkj96iYYm',
      callback: () => {

        
        console.log('locationSearch callback')

        var manager = new Microsoft.Maps.AutosuggestManager({
          placeSuggestions: false,
          addressSuggestions: true,
          map: this.map
        });
        manager.attachAutosuggest('#searchBox_' + this.id, '#searchBoxContainer_' + this.id, res => {


          console.log('locationSearch attachAutosuggest')

          let prov = res.address.district ? res.address.district : res.address.locality

          let x = city2prov.find(e => e.descr == prov.toUpperCase())
          if (x) prov = x.prov

          this.loc.Prov = prov
          this.loc.Citta = res.address.locality
          this.loc.CAP = res.address.postalCode
          this.loc.Indirizzo = res.address.addressLine

          this.loc.location_lat = res.location.latitude
          this.loc.location_lon = res.location.longitude
          this.loc.response = res

          this.map.entities.clear();
          this.map.setView({ bounds: res.bestView });
          var pushpin = new Microsoft.Maps.Pushpin(res.location);

          this.map.entities.push(pushpin);

          this.onSelected.emit(this.loc)


        });
      },
      errorCallback: () => {
        console.error('error')
      }
    }

    Microsoft.Maps.loadModule('Microsoft.Maps.AutoSuggest', x);





  }


}


const city2prov = [
  {
    "prov": "AG",
    "descr": "AGRIGENTO"
  },
  {
    "prov": "AL",
    "descr": "ALESSANDRIA"
  },
  {
    "prov": "AN",
    "descr": "ANCONA"
  },
  {
    "prov": "AO",
    "descr": "AOSTA"
  },
  {
    "prov": "AR",
    "descr": "AREZZO"
  },
  {
    "prov": "AP",
    "descr": "ASCOLI PICENO"
  },
  {
    "prov": "AT",
    "descr": "ASTI"
  },
  {
    "prov": "AV",
    "descr": "AVELLINO"
  },
  {
    "prov": "BA",
    "descr": "BARI"
  },
  {
    "prov": "BL",
    "descr": "BELLUNO"
  },
  {
    "prov": "BN",
    "descr": "BENEVENTO"
  },
  {
    "prov": "BG",
    "descr": "BERGAMO"
  },
  {
    "prov": "BI",
    "descr": "BIELLA"
  },
  {
    "prov": "BO",
    "descr": "BOLOGNA"
  },
  {
    "prov": "BZ",
    "descr": "BOLZANO"
  },
  {
    "prov": "BS",
    "descr": "BRESCIA"
  },
  {
    "prov": "BR",
    "descr": "BRINDISI"
  },
  {
    "prov": "CA",
    "descr": "CAGLIARI"
  },
  {
    "prov": "CL",
    "descr": "CALTANISSETTA"
  },
  {
    "prov": "CB",
    "descr": "CAMPOBASSO"
  },
  {
    "prov": "CE",
    "descr": "CASERTA"
  },
  {
    "prov": "CT",
    "descr": "CATANIA"
  },
  {
    "prov": "CZ",
    "descr": "CATANZARO"
  },
  {
    "prov": "CH",
    "descr": "CHIETI"
  },
  {
    "prov": "CO",
    "descr": "COMO"
  },
  {
    "prov": "CS",
    "descr": "COSENZA"
  },
  {
    "prov": "CR",
    "descr": "CREMONA"
  },
  {
    "prov": "KR",
    "descr": "CROTONE"
  },
  {
    "prov": "CN",
    "descr": "CUNEO"
  },
  {
    "prov": "EN",
    "descr": "ENNA"
  },
  {
    "prov": "FM",
    "descr": "FERMO"
  },
  {
    "prov": "FE",
    "descr": "FERRARA"
  },
  {
    "prov": "FI",
    "descr": "FIRENZE"
  },
  {
    "prov": "FG",
    "descr": "FOGGIA"
  },
  {
    "prov": "FO",
    "descr": "FORLI'"
  },
  {
    "prov": "FC",
    "descr": "FORLI' CESENA"
  },
  {
    "prov": "FR",
    "descr": "FROSINONE"
  },
  {
    "prov": "GE",
    "descr": "GENOVA"
  },
  {
    "prov": "GO",
    "descr": "GORIZIA"
  },
  {
    "prov": "GR",
    "descr": "GROSSETO"
  },
  {
    "prov": "IM",
    "descr": "IMPERIA"
  },
  {
    "prov": "IS",
    "descr": "ISERNIA"
  },
  {
    "prov": "SP",
    "descr": "LA SPEZIA"
  },
  {
    "prov": "AQ",
    "descr": "L'AQUILA"
  },
  {
    "prov": "LT",
    "descr": "LATINA"
  },
  {
    "prov": "LE",
    "descr": "LECCE"
  },
  {
    "prov": "LC",
    "descr": "LECCO"
  },
  {
    "prov": "LI",
    "descr": "LIVORNO"
  },
  {
    "prov": "LO",
    "descr": "LODI"
  },
  {
    "prov": "LU",
    "descr": "LUCCA"
  },
  {
    "prov": "MC",
    "descr": "MACERATA"
  },
  {
    "prov": "MN",
    "descr": "MANTOVA"
  },
  {
    "prov": "MS",
    "descr": "MASSA CARRARA"
  },
  {
    "prov": "MT",
    "descr": "MATERA"
  },
  {
    "prov": "ME",
    "descr": "MESSINA"
  },
  {
    "prov": "MI",
    "descr": "MILANO"
  },
  {
    "prov": "MO",
    "descr": "MODENA"
  },
  {
    "prov": "MB",
    "descr": "MONZA-BRIANZA"
  },
  {
    "prov": "NA",
    "descr": "NAPOLI"
  },
  {
    "prov": "NO",
    "descr": "NOVARA"
  },
  {
    "prov": "NU",
    "descr": "NUORO"
  },
  {
    "prov": "OR",
    "descr": "ORISTANO"
  },
  {
    "prov": "PD",
    "descr": "PADOVA"
  },
  {
    "prov": "PA",
    "descr": "PALERMO"
  },
  {
    "prov": "PR",
    "descr": "PARMA"
  },
  {
    "prov": "PV",
    "descr": "PAVIA"
  },
  {
    "prov": "PG",
    "descr": "PERUGIA"
  },
  {
    "prov": "PS",
    "descr": "PESARO"
  },
  {
    "prov": "PU",
    "descr": "PESARO URBINO"
  },
  {
    "prov": "PE",
    "descr": "PESCARA"
  },
  {
    "prov": "PC",
    "descr": "PIACENZA"
  },
  {
    "prov": "PI",
    "descr": "PISA"
  },
  {
    "prov": "PT",
    "descr": "PISTOIA"
  },
  {
    "prov": "PN",
    "descr": "PORDENONE"
  },
  {
    "prov": "PZ",
    "descr": "POTENZA"
  },
  {
    "prov": "PO",
    "descr": "PRATO"
  },
  {
    "prov": "RG",
    "descr": "RAGUSA"
  },
  {
    "prov": "RA",
    "descr": "RAVENNA"
  },
  {
    "prov": "RC",
    "descr": "REGGIO CALABRIA"
  },
  {
    "prov": "REG",
    "descr": "REGGIO EMILIA"
  },
  {
    "prov": "RI",
    "descr": "RIETI"
  },
  {
    "prov": "RN",
    "descr": "RIMINI"
  },
  {
    "prov": "RM",
    "descr": "ROMA"
  },
  {
    "prov": "RO",
    "descr": "ROVIGO"
  },
  {
    "prov": "SA",
    "descr": "SALERNO"
  },
  {
    "prov": "SS",
    "descr": "SASSARI"
  },
  {
    "prov": "SV",
    "descr": "SAVONA"
  },
  {
    "prov": "SI",
    "descr": "SIENA"
  },
  {
    "prov": "SR",
    "descr": "SIRACUSA"
  },
  {
    "prov": "SO",
    "descr": "SONDRIO"
  },
  {
    "prov": "TA",
    "descr": "TARANTO"
  },
  {
    "prov": "TE",
    "descr": "TERAMO"
  },
  {
    "prov": "TR",
    "descr": "TERNI"
  },
  {
    "prov": "TO",
    "descr": "TORINO"
  },
  {
    "prov": "TP",
    "descr": "TRAPANI"
  },
  {
    "prov": "TN",
    "descr": "TRENTO"
  },
  {
    "prov": "TV",
    "descr": "TREVISO"
  },
  {
    "prov": "TS",
    "descr": "TRIESTE"
  },
  {
    "prov": "UD",
    "descr": "UDINE"
  },
  {
    "prov": "VA",
    "descr": "VARESE"
  },
  {
    "prov": "VE",
    "descr": "VENEZIA"
  },
  {
    "prov": "VB",
    "descr": "VERBANIA"
  },
  {
    "prov": "VC",
    "descr": "VERCELLI"
  },
  {
    "prov": "VR",
    "descr": "VERONA"
  },
  {
    "prov": "VV",
    "descr": "VIBO VALENTIA"
  },
  {
    "prov": "VI",
    "descr": "VICENZA"
  },
  {
    "prov": "VT",
    "descr": "VITERBO"
  }
]