<div class="main-content"
  style="height: calc(100vh - 20px); padding-top:0; margin-bottom:0; margin-top: 1rem; padding-right: 20%;padding-left: 20%;">
  <div class="card" style="padding:1em">

    <div class="card-header card-header-info">
      <h4 class="card-title">
        <span *ngIf="tipo=='reminder'">Inserisci Reminder</span>
        <span *ngIf="tipo!='reminder'">Nota interna</span>
        <div style="float:right"><a (click)="Close()"><i class="material-icons">close</i></a>
        </div>

      </h4>
    </div><br>
    <div class="card"><br>

      <form [formGroup]="form" (keydown.enter)="$event.preventDefault()" style="height: 100%; ">

        <div class="row" style="height: 100%;  margin-bottom:0">

          <div class="col-6" style="height: 100%; margin-bottom:0">
            <h4 style="text-align: center!important; font-weight: bold;"> &nbsp;</h4>
            <mat-form-field style="padding-left: 3%;padding-right: 3%;">
              <mat-label>Categoria</mat-label>
              <mat-select [(ngModel)]="tipo" formControlName="cat">
                <mat-option *ngFor="let i of tipi" [value]="i">
                  {{i}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br>
            <mat-form-field style="padding-left: 3%;padding-right: 3%;" *ngIf="tipi1[tipo]">
              <mat-label>Dettagli</mat-label>
              <mat-select [(ngModel)]="tipo1" formControlName="cat1">
                <mat-option *ngFor="let i of tipi1[tipo]" [value]="i">
                  {{i}}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>

          <div class="col-6" style="height: 100%; margin-bottom:0">

            <div>
              <h4 style="text-align: center!important; font-weight: bold;"> Reminder</h4>
              <!-- <span [owlDateTimeTrigger]="dt1"><i class="fa fa-calendar"></i></span> -->
              <app-datetime [(model)]="datetime" (onChange)="datetimeChange($event)"></app-datetime>
            </div>



          </div>

          <div class="col-12">
            <mat-form-field style="padding-left: 3%;padding-right: 3%;">
              <h4 style="text-align: center!important; font-weight: bold;"> Nota</h4>
              <textarea matInput rows="5" style="font-size: medium;" type="textarea" [(ngModel)]="note"
                formControlName="note"></textarea>
            </mat-form-field>
          </div>

        </div>
      </form>

    </div>
    <div class="row">
      <div class="col" style="text-align: center ;">

        <button style="width: 25vh;" mat-raised-button type="submit" class="btn btn-success" (click)="Save()"
          [disabled]="isButtonDisabled() ">Salva
        </button>
      </div>


    </div>


  </div>