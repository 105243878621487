import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MysqlService } from 'app/services/mysql.service';

@Component({
  selector: 'app-reclami',
  templateUrl: './reclami.component.html',
  styleUrls: ['./reclami.component.scss']
})
export class ReclamiComponent implements OnInit {

  @Input() id: number
  @Input() role = ''

  risposta = ''
  form_reclamo: UntypedFormGroup;
  form_risposta: UntypedFormGroup;
  form_escalation: UntypedFormGroup;
  form_escalation_risp: UntypedFormGroup;
  form_escalation_chiusura: UntypedFormGroup;

  allegati

  constructor(
    private db: MysqlService,
    private formBuilder: UntypedFormBuilder,
  ) {

    //console.clear()
    console.log('ReclamiComponent - constructor')

  }

  async ngOnInit() {


    console.log('ReclamiComponent - ngOnInit')

    this.statoReclamo = (await this.db.unipolrental_get_reclamo(this.id)).item
    console.log(this.statoReclamo)

    this.allegati = this.statoReclamo.allegati ? [... this.statoReclamo.allegati] : []


    this.reclamo = {}

    this.form_reclamo = this.formBuilder.group({
      nome: ['', [Validators.required, Validators.minLength(3)]],
      cognome: ['', [Validators.required, Validators.minLength(3)]],
      richiesta: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      tel: ['', [Validators.required, Validators.minLength(3)]],
    })

    this.form_risposta = this.formBuilder.group({
      risposta: ['', [Validators.required, Validators.minLength(3)]],
    })


    this.form_escalation = this.formBuilder.group({
      escalation_richiesta: ['', [Validators.required, Validators.minLength(3)]],
    })


    this.form_escalation_risp = this.formBuilder.group({
      escalation_risposta: ['', [Validators.required, Validators.minLength(3)]],
    })

    this.form_escalation_chiusura = this.formBuilder.group({
      escalation_chiusura: ['', [Validators.required, Validators.minLength(3)]],
    })

  }


  reclamo: any
  statoReclamo

  isUploadDisabled() {

    if (this.statoReclamo.stato == 'CHIUSO') return true
    if (this.statoReclamo.stato == 'RECLAMO 1') return true
    if (this.statoReclamo.stato == 'RECLAMO 2') return true
    if (this.statoReclamo.stato == 'RISPOSTA 2') return true

    return false

  }


  rispondiReclamo() {
    this.db.unipolrental_rispondi_reclamo(this.id, this.risposta).then(async res => {

      console.log(res)

      if (res.status != 'OK') {
        alert(JSON.stringify(res))
        return
      }

      if (res.msg) {
        alert(res.msg)
        return
      }

      this.statoReclamo = (await this.db.unipolrental_get_reclamo(this.id)).item

    })
      .catch(err => {
        alert(JSON.stringify(err))
      })
  }

  openViewer(event) {

    const dataUrl = this.allegati[event.i].fileContent

    const base64String = dataUrl.split(',')[1];

    // Convertilo in un array di byte
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    // Crea un Blob con il contenuto del file PDF
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Crea un URL dal Blob
    const blobUrl = URL.createObjectURL(blob);

    // Apri il Blob in una nuova finestra o scheda
    window.open(blobUrl, '_blank');


  }

  upsertAllegatiReclamo(e) {

    console.log(this.allegati)

    this.db.unipolrental_upsert_allegati_reclamo(this.id, this.allegati).then(async res => {
      console.log(res)
    })

  }


  insertReclamo() {
    this.db.unipolrental_upsert_reclamo(this.id, this.reclamo).then(async res => {

      console.log(res)

      if (res.status != 'OK') {
        alert(JSON.stringify(res))
        return
      }

      if (res.msg) {
        alert(res.msg)
        return
      }


      this.statoReclamo = (await this.db.unipolrental_get_reclamo(this.id)).item

      alert("Operazione completata, verrete ricontattati il prima possibile ai contatti forniti")


    })
      .catch(err => {
        alert(JSON.stringify(err))
      })
  }

  escalation_richiesta = undefined
  showEscalation = false;
  toggleEscalation() {
    this.showEscalation = !this.showEscalation
  }

  inviaEscalation() {

    let escalation_richiesta = {
      escalation_richiesta: this.escalation_richiesta
    }

    this.db.unipolrental_upsert_reclamo(this.id, escalation_richiesta).then(async res => {

      console.log(res)

      if (res.status != 'OK') {
        alert(JSON.stringify(res))
        return
      }

      if (res.msg) {
        alert(res.msg)
        return
      }


      this.statoReclamo = (await this.db.unipolrental_get_reclamo(this.id)).item

      alert("Operazione completata")


    })
      .catch(err => {
        alert(JSON.stringify(err))
      })
  }


  escalation_risposta = undefined
  rispondiEscalation() {

    let escalation_risposta = {
      escalation_risposta: this.escalation_risposta
    }

    this.db.unipolrental_upsert_reclamo(this.id, escalation_risposta).then(async res => {

      console.log(res)

      if (res.status != 'OK') {
        alert(JSON.stringify(res))
        return
      }

      if (res.msg) {
        alert(res.msg)
        return
      }


      this.statoReclamo = (await this.db.unipolrental_get_reclamo(this.id)).item

      alert("Operazione completata")


    })
      .catch(err => {
        alert(JSON.stringify(err))
      })
  }

  escalation_chiusura
  chiudiEscalation() {

    let escalation_chiusura = {
      escalation_chiusura: this.escalation_chiusura
    }

    this.db.unipolrental_upsert_reclamo(this.id, escalation_chiusura).then(async res => {

      console.log(res)

      if (res.status != 'OK') {
        alert(JSON.stringify(res))
        return
      }

      if (res.msg) {
        alert(res.msg)
        return
      }


      this.statoReclamo = (await this.db.unipolrental_get_reclamo(this.id)).item

      alert("Operazione completata")


    })
      .catch(err => {
        alert(JSON.stringify(err))
      })
  }
}
