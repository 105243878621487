import { NgModule } from "@angular/core";
import { CountdownPipe } from "./countdown.pipe";
import { CommonModule } from "@angular/common";
import { DurationPipe } from "./duration.pipe";
import { PresentImagePipe } from "./present-image.pipe";
import { MultilinePipe } from "./multiline.pipe";
import { NameToInitialPipe } from "./name-to-initial.pipe";
import { MyNumberPipe } from "./my-number.pipe";
import { UserFilterByParentPipe } from "./user-filter-by-parent.pipe";
import { FilterByFileTypePipe } from "./filter-by-file-type.pipe";

@NgModule({

  declarations: [
    CountdownPipe,
    DurationPipe,
    PresentImagePipe,
    MultilinePipe,
    NameToInitialPipe,
    UserFilterByParentPipe,
    FilterByFileTypePipe,
    MyNumberPipe,

  ],
  imports: [
    CommonModule,
  ],
  exports: [
    CountdownPipe,
    DurationPipe,
    PresentImagePipe,
    MultilinePipe,
    NameToInitialPipe,
    UserFilterByParentPipe,
    FilterByFileTypePipe,
    MyNumberPipe,
  ],

  providers: [
  ]
})
export class PipesModule { }
