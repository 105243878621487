
//import { ReadMode } from 'ngx-file-helpers';
import { IFile } from './../../models/incarico.interface';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DecimalPipe } from '@angular/common'
import { FilterByFileTypePipe } from '../../pipes/filter-by-file-type.pipe';
import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import * as FileSaver from 'file-saver'
import { NgxFileDropEntry } from 'ngx-file-drop';
import { getIsMobile } from 'app/app.component';
@Component({
	selector: 'app-gallery',
	templateUrl: './gallery.component.html',
	styleUrls: ['./gallery.component.scss']
})

export class GalleryComponent implements OnInit {

	isMultiple = true

	file_rand = 'file_' + Math.floor(Math.random() * 1000);

	@ViewChild('file_rand') file: HTMLInputElement

	public readMode = 2;
	downloading = false;

	isMobile = getIsMobile();

	@Output() onSelected = new EventEmitter<any>();
	@Output() onAdd = new EventEmitter<string>();
	@Output() onDelete = new EventEmitter<any>();

	filtered_source: IFile[] = [];
	_source: IFile[] = [];

	@Input() componentType;

	@Input() //value: IResult
	set source(source: IFile[]) {
		//this.getContext();
		//console.log('prev value: ', this._source);
		//console.log('got name: ', source);
		this._source = source;
		if (!this._source) this._source = [];

		//this.class=`{'blueprint':true, '${this.value.vehType}'}`

		this.refresh()


	}


	@Input() dropzone: boolean = true;

	@Input() tag
	@Input() showTag: boolean = false;
	@Input() accepted: string = '';
	@Input() disabled: boolean = false;
	@Input() uploader: boolean = false;

	@Input() categorized: boolean = true;
	@Input() showLabel: boolean = true;


	@Input() viewGallery: boolean = true;

	@Input() customDocTypes: string[] = []
	@Input() concordato: string = '';


	@Input() filters = ['img', 'pdf', 'oth'];

	filter: string[] = this.categorized ? ['img'] : ['img', 'pdf', 'oth'];

	type = '';

	setFilter(filter: string[]) {
		this.filter = filter
		this.refresh()
	}

	refresh() {

		//console.log('refresh, tag:' + this.tag)

		if (!this.filter) this.filter = this.filters;
		//console.log('gallery filter:', this.filter)
		//console.log('gallery source:', this._source)

		if (this.categorized) this.filter = this.filters;



		let p = new FilterByFileTypePipe()
		this.filtered_source = p.transform(this._source, this.filter)

		/*
		
		.sort((a, b) => {
			return -('' + a.creationDate).localeCompare(b.creationDate)
		})

		*/

		if (this.tag != undefined)
			this.filtered_source = this.filtered_source.filter(e => ('' + this.tag) == ('' + e.tag))

	}


	constructor(private decimalPipe: DecimalPipe) {
		this.refresh();

	}

	ngOnInit() {
		this.refresh();
	}

	downloadAll() {
		this.downloading = true

		function dataURLtoUint8Array(dataURL) {
			const base64String = dataURL.split(',')[1]; // Rimuove l'intestazione
			const binaryString = atob(base64String);
			const len = binaryString.length;
			const bytes = new Uint8Array(len);
			for (let i = 0; i < len; i++) {
				bytes[i] = binaryString.charCodeAt(i);
			}
			return bytes;
		}

		let zip = new JSZip();
		let count = 0

		this._source.forEach(element => {
			console.log(element)


			if (element.url) {
				JSZipUtils.getBinaryContent(element.url, (err, data) => {
					if (err) {
						count++;
						return
					}
					zip.file(element.fileName, data, { binary: true });
					count++;
					if (count == this._source.length) {

						zip.generateAsync({ type: "blob" })
							.then((zipFile) => {

								console.log('async gen')

								FileSaver.saveAs(zipFile, 'allegati.zip');

								this.downloading = false

							}).catch(err => console.error(err));


					}
				});

			} else if (element.fileContent && element.fileContent != '') {
				const byteArray = dataURLtoUint8Array(element.fileContent);
				zip.file(element.fileName, byteArray);
				count++;


				if (count == this._source.length) {

					zip.generateAsync({ type: "blob" })
						.then((zipFile) => {

							console.log('async gen')

							FileSaver.saveAs(zipFile, 'allegati.zip');

							this.downloading = false

						}).catch(err => console.error(err));


				}
			}

		});



	}

	//////////////////////////// FILES ////////////////////////////
	getBase64(file: File, refresh = true) {
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = res => {

			let tmp: IFile = {
				fileName: file.name,
				size: file.size,
				fileContent: '' + reader.result,
				creationDate: new Date(),
				tag: this.tag,
				type: this.type
			}

			//if (!this._source) this._source = [];
			this._source.push(tmp);

			if (refresh) {
				this.refresh();
				this.onAdd.emit(this.type);
			}
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	}


	startUpload(t) {
		this.isMultiple = t ? true : false
		this.type = t
		//this.file.value = "";
	}

	ffile
	uploadFile(event) {
		console.log(event)

		if (this.accepted != '') {
			const allowedTypes = this.accepted.split(',');

			for (let i = 0; i < event.target.files.length; i++) {
				const file = event.target.files[i]

				if (file && !allowedTypes.includes(file.type)) {
					alert('Puoi caricare solo i tipi file specificati.');
					return
				}
			}
		}

		for (let i = 0; i < event.target.files.length; i++) {
			const file: File = event.target.files[i];
			this.getBase64(file, false);
		}


		setTimeout(() => {
			this.refresh();
			this.onAdd.emit();
			this.ffile = ''
		}, 1000);


	}

	timer;

	async dropFiles(files: NgxFileDropEntry[]) {

		for (const file of files) {

			const fileEntry = file.fileEntry as FileSystemFileEntry;


			fileEntry.file((file: File) => {

				this.getBase64(file, true)


				//				this.dropFile(e)

			})


		}

	}

	humanize(val: number) {

		if (val < 1024) {
			return this.decimalPipe.transform(val, '.1-1') + 'B';
		}

		val = val / 1024;
		if (val < 1024) {
			return this.decimalPipe.transform(val, '.1-1') + 'KB';
		}

		val = val / 1024;
		if (val < 1024) {
			return this.decimalPipe.transform(val, '.1-1') + 'MB';
		}

		val = val / 1024;
		if (val < 1024) {
			return this.decimalPipe.transform(val, '.1-1') + 'GB';
		}

		val = val / 1024;
		return this.decimalPipe.transform(val, '.1-1') + 'TB';

	}

	isImage(f: IFile) {
		let ext
		try {
			ext = f.fileName.slice(-3).toLocaleLowerCase();
		} catch (error) {
			ext = 'jpg'
		}

		switch (ext) {
			case 'jpg':
			case 'png':
			case 'gif':
			case 'peg':
				return f.fileContent != '' && f.fileContent ? f.fileContent : f.url;

			case 'pdf':
			case 'adz':
				return 'assets/img/pdf.png'

			default:
				return 'assets/img/attachment.png'

		}
	}

	openFile(i: number) {
		this.onSelected.emit({ i: i, filter: this.filter, tag: this.tag });
	}
	isConcordato(i) {
		return i.type == "concordato"
	}

	delete(e: IFile) {

		console.clear();

		if (confirm('Eliminare il file selezionato?'))
			this._source.forEach((el, i) => {

				console.log(el)

				if (el.fileName == e.fileName) {

					console.log('found')


					console.log(this._source.length)

					this._source.splice(i, 1)

					console.log(this._source.length)


					this.refresh();

					this.onDelete.emit(this._source);

					return;
				}
			})



	}

	public fileOver(event) {
		console.log(event);
	}

	public fileLeave(event) {
		console.log(event);
	}


}
