import { Component, OnInit, ViewEncapsulation, Inject, HostListener, } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MysqlService } from 'app/services/mysql.service';
import { UserService } from 'app/services/user.service';


@Component({
    selector: 'app-order-photo-perizie',
    templateUrl: './order-photo.component.html',
    styleUrls: ['./order-photo.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class PerizieOrderPhotoComponent implements OnInit {

    @HostListener('window:beforeunload', ['$event'])
    async handleClose($event) {
        //console.clear()
        console.log($event)

        $event.returnValue = "ATTENZIONE, la pratica non è stata completata, ordinare le immagini e premere il tasto salva per completare la consegna";

        //$event.returnValue = true;
    }

    placeholders = [
        { id: 1, label: 'Vista Ant. Sx', placeholder: '/assets/img/placeholders/7.png', missing: false },
        { id: 2, label: 'Vista Ant. Dx', placeholder: '/assets/img/placeholders/1.png', missing: false },
        { id: 3, label: 'Vista Post. Dx', placeholder: '/assets/img/placeholders/3.png', missing: false },
        { id: 4, label: 'Vista Post. Sx', placeholder: '/assets/img/placeholders/5.png', missing: false },
        { id: 5, label: 'Plancia', placeholder: '/assets/img/placeholders/plancia.png', missing: false },
        { id: 6, label: 'Strumenti (motore acceso)', placeholder: '/assets/img/placeholders/quadro-strumenti.png' },
        { id: 7, label: 'Tetto', placeholder: '/assets/img/placeholders/tetto.png', missing: false },
        { id: 8, label: 'Cofano', placeholder: '/assets/img/placeholders/cofano.png', missing: false },
        { id: 9, label: 'Doppie Chiavi', placeholder: '/assets/img/placeholders/doppie-chiavi.png', missing: false, tag: 'chiavi', descr: 'ATTENZIONE, inserire solo e unicamente foto se presenti DOPPIE CHIAVI' },
        { id: 10, label: 'Libretto', placeholder: '/assets/img/placeholders/libretto.png', missing: false, tag: 'cdc', descr: 'ATTENZIONE, inserire solo e unicamente foto se libretto CONFORME' },
    ]

    allegati = JSON.parse(JSON.stringify(this.data.allegati)).sort((a, b) => a.fileName > b.fileName ? 1 : -1)

    constructor(
        public db: MysqlService,
        private userService: UserService,
        public dialogRef: MatDialogRef<PerizieOrderPhotoComponent>,
        @Inject(MAT_DIALOG_DATA) public data,

    ) {

        console.log(data)

        this.checkAdz()

    }

    ngOnInit() {

    }

    showZoom = false
    zoomData
    zoom(a = undefined) {
        this.showZoom = a != undefined
        this.zoomData = a

        console.log(a)

    }

    allowDrop(ev) {
        ev.preventDefault();
    }

    drag(ev, e, r = undefined, c = undefined) {

        ev.target.style.opacity = '0.4';

        console.log('START DRAG')

        ev.dataTransfer.setData('text', JSON.stringify({
            r: r, c: c,
            data: e
        }));

        let dragIcon = document.createElement('img');
        dragIcon.src = e.url;
        dragIcon.width = 200;
        let div = document.createElement('div');
        div.appendChild(dragIcon);
        document.querySelector('body').appendChild(div);
        ev.dataTransfer.setDragImage(div, 0, 0);
        /*
                let dragIcon = document.createElement('img');
                dragIcon.src = e.url;
                dragIcon.width = 10;
                dragIcon.style.width = '10px';
                ev.dataTransfer.setDragImage(dragIcon, 0, 0);
        */
    }

    dragEnd(ev) {
        console.log('DRAG END')
        ev.target.style.opacity = '1';
    }

    drop(ev, placeholder) {


        if (!placeholder) return;

        console.log('DROP')

        ev.preventDefault();
        let data = JSON.parse(ev.dataTransfer.getData('text')).data;
        console.log(data);

        let img = this.allegati.find(e => e.hash == data.hash)

        this.resetPlaceholder(placeholder)

        placeholder.missing = false
        img.placeholder = placeholder


        this.checkAdz()

    }

    resetPlaceholder(placeholder) {
        this.allegati = this.allegati.map(e => {
            if (e.placeholder?.id == placeholder.id) {
                delete e.placeholder
            }
            return e
        })


        this.checkAdz()
    }

    getImage(placeholderId) {

        return this.allegati.find(e => e.placeholder?.id == placeholderId)

    }


    adzVoceChiavi: boolean
    adzVoceCdc: boolean

    missingChiavi: boolean
    missingCdc: boolean


    checkAdz() {

        this.adzVoceChiavi = this.data.adz_data.adz.danni.find(e =>
            e.voce_di_danno.val.toLowerCase().includes('chiav') && (
            +e.costo_ricambi_imponibile.val == 300 || +e.costo_ricambi_imponibile.val == 250 ) ) != undefined

        this.adzVoceCdc = this.data.adz_data.adz.danni.find(e => (
            e.voce_di_danno.val.toLowerCase().includes('libretto') ||
            e.voce_di_danno.val.toLowerCase().includes('cdc') ||
            e.voce_di_danno.val.toLowerCase().includes('circolazione')
        ) && +e.costo_ricambi_imponibile.val == 300) != undefined

        console.log('voceChiavi: ' + this.adzVoceChiavi)
        console.log('voceCdc: ' + this.adzVoceCdc)

        this.missingChiavi = !this.getImage(this.placeholders.find(e => e.tag == 'chiavi').id)
        this.missingCdc = !this.getImage(this.placeholders.find(e => e.tag == 'cdc').id)


        let b = true

        if (this.missingChiavi != this.adzVoceChiavi) {
            b = false
        }
        if (this.missingCdc != this.adzVoceCdc) {
            b = false
        }

        return b
    }

    save() {

        let j = 1
        for (let i = 0; i < this.allegati.length; i++) {

            let tmp = this.allegati[i].fileName.split('.')
            let ext = tmp.splice(tmp.length - 1, 1)
            let newName = ''
            if (this.allegati[i].placeholder && +this.allegati[i].placeholder.id < 9) {
                newName = this.allegati[i].placeholder.id + '.' + ext
            } else {
                newName = 'F' + ('' + j).padStart(3, '0') + '.' + ext
                j++
            }

            this.allegati[i].fileName = newName


        }

        this.allegati = this.allegati.sort((a, b) => a.fileName > b.fileName ? 1 : -1)

        this.dialogRef.close({ allegati: this.allegati, warning: !this.checkAdz() })

    }

    isDisabled() {
        return this.placeholders.filter(e => !e.missing && !this.getImage(e.id)).length > 0
    }

    close() {
        this.dialogRef.close()
    }

    alertClose() {
        alert("Attenzione, per conludere la pratica assegnare le foto trascinandole nelle rispettive posizioni e premere il tasto salva")
    }

    isImage(a){

        return a?.fileName?.toLowerCase().endsWith('jpg') || a?.fileName?.toLowerCase().endsWith('jpeg') 

    }

}
