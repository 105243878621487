import { IChat } from './models/chat.interface';
import { UserService } from './../../services/user.service';
import { MysqlService } from './../../services/mysql.service';
import { Subscription } from 'rxjs';
import { Component, OnDestroy, Input, ElementRef, ViewChild, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'ngx-chat',
  templateUrl: 'chat.component.html',
  styleUrls: ['chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  @Input() asButton: boolean = false;
  @Input() code: string;
  @Input() backoffice = false;
  @Input() recipient: string;
  @Input() group: boolean = false;


  @Input() readonly: boolean = false;

  hidden = true;

  public msg: string;

  msgs: IChat[] = [];
  s: Subscription;
  me: string;
  role: string;

  timer;

  constructor(private db: MysqlService, private userService: UserService) {


  }

  ngOnInit() {
    this.me = this.userService.getCurrentUser().email;
    this.role = this.userService.getRole();

    if (this.role == 'GESTORE' || this.role == 'ADMIN')
      this.me = 'GESTORE'

    this.loadData(true, false);
  }

  lastChat=0;
  async loadData(loop: boolean, force: boolean) {


    await this.db.getListChat(this.code, this.recipient).subscribe(res => {

      let refresh = false

      try {
        refresh = this.lastChat < +res[res.length - 1].ID
        this.lastChat = +res[res.length - 1].ID
      } catch (error) {

      }

      if (force || refresh) {

        console.log('chat loadData')
        
        res.forEach(m => {
          m.date = moment(m.date + 'Z').tz("Europe/Rome").format('DD/MM/YYYY hh:mm:ss')
        })

        this.msgs = res;

        setTimeout(() => { this.scrollToBottom() }, 25);

      }

      if (loop) this.timer = setTimeout(() => { this.loadData(true, false) }, 2500);

    })


  }

  scrollToBottom(): void {
    try {

      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {
    }
  }

  send() {

    if (this.msg == '') return;



    let chat: IChat = {
      ID: null,
      sender: this.me,
      date: new Date(),
      recipient: this.recipient,
      code: this.code,
      message: this.msg,
      group: this.group,
    }


    this.db.addChat(chat).then(res => {
      console.log(res);
      this.msg = '';
      this.loadData(false, true);
    }).catch(err => console.error(err));

  }

  ngOnDestroy() {

    clearTimeout(this.timer);

  }

  show() {

    if (!this.hidden) return;

    this.loadData(true, true);
    this.hidden = false;
  }

  close() {
    clearTimeout(this.timer);
    this.hidden = true;
  }



  normalizeEmail(s: string) {
    try {
      return s.split('@')[0]
    } catch (error) {

    }

    return s
  }
}

