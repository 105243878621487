<!-- 

                 
                      <embed *ngIf="pdf_perizia" [src]="pdf_perizia" style="height: 60vh;  width:100%; "
                        class="embedSet" type="application/pdf">
-->

<div [ngClass]="{'warn': ! (+item?.adz?.km?.val >0), 'km':  (+item?.adz?.km?.val >0) }" >
  {{+item?.adz?.km?.val > 0 ? 'Km: ' + (+item?.adz?.km?.val/1000 |number: '.0-0') : 'ATTENZIONE, KM MANCANTI' }}
</div>


<table class="adz">

  <tr class="adz">
    <!--<th class="adz little"></th>-->
    <th *ngIf="cboxEnabled" class="adz little">&nbsp;</th>
    <th class="adz little">&nbsp;</th>
    <th class="adz little">&nbsp;</th>
    <th class="adz center little lborder" colspan=2>SR</th>
    <th class="adz center little lborder" colspan=2>LA</th>
    <th class="adz center little lborder" colspan=2>VE</th>
    <th class="adz center little lborder">ME</th>
    <th class="adz little center ">D.M.</th>
    <th class="adz little">Costo&nbsp;ricambi</th>
  </tr>
  <tr class="adz">
    <!--<th class="adz little">C.R.</th>-->
    <td *ngIf="cboxEnabled" class="adz little">
      <mat-slide-toggle (change)="cboxClickedAll($event)" [checked]="isAllChecked()" [disabled]="disabled">
      </mat-slide-toggle>
    </td>

    <th class="adz little">Voci di danno</th>
    <th class="adz little">L.</th>
    <th class="adz little lborder">D.</th>
    <th class="adz little">Tempo</th>
    <th class="adz little lborder">D.</th>
    <th class="adz little">Tempo</th>
    <th class="adz little lborder">D.</th>
    <th class="adz little">Tempo</th>
    <th class="adz little lborder">Tempo</th>
    <th class="adz little center ">S.C.</th>
    <th class="adz little ">(IVA Esclusa)</th>
    <th class="adz little">&nbsp;</th>
  </tr>
  <tr class="adz" *ngFor="let d of getRows();  index as i">
    <!--<td class="adz">{{d.codice_ricambio.val}}</td>-->

    <td *ngIf="cboxEnabled" class="adz little">
      <mat-slide-toggle (change)="cboxClicked($event)"  [(ngModel)]="d.selected"
        [disabled]="disabled">
      </mat-slide-toggle>
    </td>

    <td class="adz adztable">{{d.voce_di_danno.val}}</td>
    <td class="adz adztable">{{d.lato.val}}</td>
    <td *ngIf="!noval" class="adz adztable center lborder">{{d.sr_diff.val}}</td>
    <td *ngIf="!noval" class="adz adztable right">{{d.sr_tempo.val/100 |myNumber:'.2-2'}}</td>
    <td *ngIf="!noval" class="adz adztable center lborder">{{d.la_diff.val}}</td>
    <td *ngIf="!noval" class="adz adztable right">{{d.la_tempo.val/100 |myNumber:'.2-2'}}</td>
    <td *ngIf="!noval" class="adz adztable center lborder">{{d.ve_diff.val}}</td>
    <td *ngIf="!noval" class="adz adztable right">{{d.ve_tempo.val/100 |myNumber:'.2-2'}}</td>
    <td *ngIf="!noval" class="adz adztable right lborder">{{d.me_tempo.val/100 |myNumber:'.2-2'}}</td>
    <td *ngIf="!noval" class="adz adztable">{{d.detraz_sconto.val }}</td>
    <td *ngIf="!noval" class="adz  right">{{ d.costo_ricambi_imponibile.val |myNumber:'.2-2'}}</td>

    <td class="adz adztable center">
      <i *ngIf="!showOnlySelected && !disabled && cboxEnabled && !isCustom(d.voce_di_danno.val)"
        class="material-icons edit" (click)="startEditRow(i)">edit</i>
    </td>

  </tr>

  <tr class="adz" *ngIf="!noval">
    <!--<td class="adz">{{d.codice_ricambio.val}}</td>-->

    <td *ngIf="cboxEnabled" class="adz little">

    </td>

    <td class="adz adztable"></td>
    <td class="adz adztable"></td>
    <td class="adz adztable center"></td>
    <td class="adz adztable right"></td>
    <td class="adz adztable center"></td>
    <td class="adz adztable right"></td>
    <td class="adz adztable center"></td>
    <td class="adz adztable right"></td>
    <td class="adz adztable right"></td>
    <td class="adz adztable"></td>
    <td class="adz  right"></td>

    <td class="adz adztable center">
      <i *ngIf="!showOnlySelected && !disabled && cboxEnabled" class="material-icons edit"
        (click)="startEditRow()">add</i>
    </td>

  </tr>

  <tr class="adz "  *ngIf="!noval">
    <!--<th class="adz"></th>-->
    <th *ngIf="cboxEnabled" class="adz little">&nbsp;</th>
    <th class="adz "></th>
    <th class="adz  center"></th>
    <th class="adz  right">
      <span class="full-selected" *ngIf="!showOnlySelected">{{ getOnlySelectedValue('sr_tempo',true)/100
        |myNumber:'.2-2'}}
      </span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getOnlySelectedValue('sr_tempo')/100 |myNumber:'.2-2'}}</span>
    </th>
    <th class="adz  center"></th>
    <th class="adz  right"><span class="full-selected"
        *ngIf="!showOnlySelected">{{getOnlySelectedValue('la_tempo',true)/100 |myNumber:'.2-2'}}</span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getOnlySelectedValue('la_tempo')/100 |myNumber:'.2-2'}}</span>
    </th>
    <th class="adz  center"></th>
    <th class="adz  right"><span class="full-selected"
        *ngIf="!showOnlySelected">{{getOnlySelectedValue('ve_tempo',true)/100 |myNumber:'.2-2'}}</span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getOnlySelectedValue('ve_tempo')/100 |myNumber:'.2-2'}}</span>
    </th>
    <th class="adz  right"><span class="full-selected"
        *ngIf="!showOnlySelected">{{getOnlySelectedValue('me_tempo',true)/100 |myNumber:'.2-2'}}</span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getOnlySelectedValue('me_tempo')/100 |myNumber:'.2-2'}}</span>
    </th>
    <th class="adz "></th>
    <th class="adz  right"><span class="full-selected"
        *ngIf="!showOnlySelected">{{getOnlySelectedValue('costo_ricambi_imponibile',true) |myNumber:'.2-2'}}</span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getOnlySelectedValue('costo_ricambi_imponibile') |myNumber:'.2-2'}}</span>
    </th>
  </tr>

</table>


<br>
<table class="adz" *ngIf="!noval">
  <tr class="adz">
    <td class="adz little center">Suppl. doppiostrato</td>
    <td class="adz little center">Suppl. finitura</td>
    <td class="adz little center">Tempo agg. verniciatura</td>
    <td class="adz little center">Totale tempi suppl.</td>
    <th class="adz little center">Totale tempi VE</th>
  </tr>
  <tr class="adz">
    <td class="adz center">
      <span class="full-selected" *ngIf="!showOnlySelected">
        {{getSupplDoppioStrato(true)/100 |myNumber:'.2-2'}}
      </span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getSupplDoppioStrato()/100 |myNumber:'.2-2'}}</span>
    </td>
    <td class="adz center">
      <span class="full-selected" *ngIf="!showOnlySelected">
        {{getSupplFinitura(true)/100 |myNumber:'.2-2'}}
      </span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getSupplFinitura()/100 |myNumber:'.2-2'}}</span>
    </td>
    <td class="adz center">
      <span class="full-selected" *ngIf="!showOnlySelected">
        {{getTAggVe(true)/100 |myNumber:'.2-2'}}
      </span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getTAggVe()/100 |myNumber:'.2-2'}}</span>
    </td>
    <td class="adz center">
      <span class="full-selected" *ngIf="!showOnlySelected">
        {{getSupplTot(true)/100 |myNumber:'.2-2'}}</span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getSupplTot()/100 |myNumber:'.2-2'}}</span>
    </td>
    <th class="adz center">
      <span class="full-selected" *ngIf="!showOnlySelected">
        {{getTotVE(true)/100 |myNumber:'.2-2'}}</span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getTotVE()/100 |myNumber:'.2-2'}}</span>
    </th>
  </tr>
</table>


<br>
<table class="adz" *ngIf="!noval">
  <tr class="adz">
    <th class="adz little"></th>
    <th class="adz right little">IMPONIBILE</th>
    <th class="adz right little">IVA</th>
    <th class="adz right little">TOTALE</th>
  </tr>

  <tr class="adz">
    <th class="adz little">Ricambi</th>
    <td class="adz right">
      <span class="full-selected" *ngIf="!showOnlySelected">{{getOnlySelectedValue('costo_ricambi_imponibile',true)
        |myNumber:'.2-2'}}</span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getOnlySelectedValue('costo_ricambi_imponibile') |myNumber:'.2-2'}}</span>
    </td>
    <td class="adz right">
      <span class="full-selected" *ngIf="!showOnlySelected">{{getOnlySelectedValue('costo_ricambi_imponibile',true) *
        0.22 |myNumber:'.2-2'}}</span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getOnlySelectedValue('costo_ricambi_imponibile') * 0.22
        |myNumber:'.2-2'}}</span>
    </td>
    <td class="adz right">
      <span class="full-selected" *ngIf="!showOnlySelected">{{getOnlySelectedValue('costo_ricambi_imponibile',true) *
        1.22 |myNumber:'.2-2'}}</span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getOnlySelectedValue('costo_ricambi_imponibile') * 1.22
        |myNumber:'.2-2'}}</span>
    </td>
  </tr>

  <tr class="adz">
    <td class="adz little"><b>Materiale di consumo</b><br>
      ore <span class="full-selected" *ngIf="!showOnlySelected">{{getTotVE(true)/100 |myNumber:'.2-2'}}</span>

      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getTotVE()/100 |myNumber:'.2-2'}}</span>
      x € {{ +item?.adz?.materiali_di_consumo.val / (+item?.adz?.totale_tempi_ve.val/100) |myNumber:'.2-2'}}
    </td>
    <td class="adz right">
      <span class="full-selected" *ngIf="!showOnlySelected">{{getTotMdC(true)|myNumber:'.2-2'}}</span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getTotMdC() |myNumber:'.2-2'}}</span>
    </td>
    <td class="adz right">
      <span class="full-selected" *ngIf="!showOnlySelected">{{getTotMdC(true)*0.22|myNumber:'.2-2'}}</span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getTotMdC()*0.22|myNumber:'.2-2'}}</span>
    </td>
    <td class="adz right">
      <span class="full-selected" *ngIf="!showOnlySelected">{{getTotMdC(true)*1.22|myNumber:'.2-2'}}</span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getTotMdC()*1.22|myNumber:'.2-2'}}</span>
    </td>
  </tr>

  <tr class="adz">
    <th class="adz little">Uso dime</th>
    <td class="adz right">{{+item?.adz?.uso_dime_imp.val |myNumber:'.2-2'}}</td>
    <td class="adz right">{{+item?.adz?.uso_dime_iva.val |myNumber:'.2-2'}}</td>
    <td class="adz right">{{+item?.adz?.uso_dime_ivato.val |myNumber:'.2-2'}}</td>
  </tr>



  <tr class="adz">
    <td class="adz little"><b>Manodopera carrozzeria</b><br> ore
      <span class="full-selected" *ngIf="!showOnlySelected"> {{getTotMCA(true)|number:'.2-2'}}</span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getTotMCA() |myNumber:'.2-2'}}</span>
      x €
      {{+item?.adz?.costo_mdo_carrozzeria.val |myNumber:'.2-2'}}
    </td>
    <td class="adz right">
      <span class="full-selected" *ngIf="!showOnlySelected">{{getTotMCA(true)*getMCAPrice() |myNumber:'.2-2'}}</span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getTotMCA()*getMCAPrice() |myNumber:'.2-2'}}</span>
    </td>
    <td class="adz right">
      <span class="full-selected" *ngIf="!showOnlySelected">
        {{getTotMCA(true)*getMCAPrice()*0.22|myNumber:'.2-2'}}</span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getTotMCA()*getMCAPrice() * 0.22|myNumber:'.2-2'}}</span>
    </td>
    <td class="adz right">
      <span class="full-selected"
        *ngIf="!showOnlySelected">{{getTotMCA(true)*getMCAPrice()*1.22|myNumber:'.2-2'}}</span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getTotMCA()*getMCAPrice()*1.22|myNumber:'.2-2'}}</span>
    </td>
  </tr>

  <tr class="adz">
    <td class="adz little"><b>Manodopera meccanica</b><br> ore
      <span class="full-selected" *ngIf="!showOnlySelected">{{getTotMMec(true)|number:'.2-2'}}</span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getTotMMec() |myNumber:'.2-2'}}</span>
      x €
      {{+item?.adz?.costo_mdo_meccanica.val|myNumber:'.2-2'}}
    </td>
    <td class="adz right">
      <span class="full-selected" *ngIf="!showOnlySelected">{{getTotMMec(true)*getMMecPrice() |myNumber:'.2-2'}}</span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getTotMMec()*getMMecPrice() |myNumber:'.2-2'}}</span>
    </td>
    <td class="adz right">
      <span class="full-selected" *ngIf="!showOnlySelected">{{getTotMMec(true)*getMMecPrice()
        *0.22|myNumber:'.2-2'}}</span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getTotMMec()*getMMecPrice() *0.22|myNumber:'.2-2'}}</span>
    </td>
    <td class="adz right">
      <span class="full-selected" *ngIf="!showOnlySelected">{{getTotMMec(true)*getMMecPrice()
        *1.22|myNumber:'.2-2'}}</span>
      <span *ngIf="!showOnlySelected"> / </span><span class="only-selected"
        [ngStyle]="{'color': altColor}">{{getTotMMec()*getMMecPrice() * 1.22|myNumber:'.2-2'}}</span>
    </td>
  </tr>

  <tr class="adz">
    <th class="adz little">TOTALI</th>
    <td class="adz right">
      <span class="full-selected" *ngIf="!showOnlySelected"
        [ngClass]="{'custom-del': item.adz.totImponibilePersonalizzato != undefined}">
        {{ getTot(true)|myNumber:'.2-2' }}</span>

      <span [ngClass]="{'custom-del': item.adz.totImponibilePersonalizzato != undefined}" *ngIf="!showOnlySelected"> /
      </span>

      <span class="only-selected" [ngStyle]="{'color': altColor}"
        [ngClass]="{'custom-del': item.adz.totImponibilePersonalizzato != undefined}"
        *ngIf="!showOnlySelected || item?.adz?.totImponibilePersonalizzato == undefined">{{getTot()
        |myNumber:'.2-2'}}</span>


      <i *ngIf="!showOnlySelected && !disabled && cboxEnabled" class="material-icons edit"
        (click)="startEdit()">edit</i>

      <div *ngIf="item?.adz?.totImponibilePersonalizzato">{{item.adz.totImponibilePersonalizzato |myNumber:'.2-2'}}
      </div>

    </td>
    <td class="adz right">

      <span class="full-selected" *ngIf="!showOnlySelected"
        [ngClass]="{'custom-del': item.adz.totImponibilePersonalizzato != undefined}">
        {{ getTot(true) *0.22 |myNumber:'.2-2' }}</span>

      <span *ngIf="!showOnlySelected" [ngClass]="{'custom-del': item.adz.totImponibilePersonalizzato != undefined}"> /
      </span>

      <span class="only-selected" [ngStyle]="{'color': altColor}"
        [ngClass]="{'custom-del': item.adz.totImponibilePersonalizzato != undefined}"
        *ngIf="!showOnlySelected || item?.adz?.totImponibilePersonalizzato == undefined">
        {{getTot() * 0.22|myNumber:'.2-2'}}</span>

      <div *ngIf="item?.adz?.totImponibilePersonalizzato">
        {{item.adz.totImponibilePersonalizzato *0.22 |myNumber:'.2-2'}}</div>

    </td>

    <td class="adz right">

      <span class="full-selected" *ngIf="!showOnlySelected"
        [ngClass]="{'custom-del': item.adz.totImponibilePersonalizzato != undefined}">
        {{getTot(true) * 1.22|myNumber:'.2-2'}}
      </span>

      <span *ngIf="!showOnlySelected" [ngClass]="{'custom-del': item.adz.totImponibilePersonalizzato != undefined}"> /
      </span>

      <span class="only-selected" [ngStyle]="{'color': altColor}"
        [ngClass]="{'custom-del': item.adz.totImponibilePersonalizzato != undefined}"
        *ngIf="!showOnlySelected || item?.adz?.totImponibilePersonalizzato == undefined">
        {{getTot() * 1.22|myNumber:'.2-2'}}</span>

      <div *ngIf="item?.adz?.totImponibilePersonalizzato">
        {{item.adz.totImponibilePersonalizzato *1.2 |myNumber:'.2-2'}}</div>

    </td>
  </tr>


</table>
<br>

<table class="adz" *ngIf="!noval">
  <tr class="adz">
    <th class="adz right">Importo richiesto</th>
    <th class="adz right">Importo concordato</th>
    <th class="adz right">Fermo tecnico</th>
  </tr>
  <tr class="adz">
    <th class="adz left">Osservazioni: </th>
    <th colspan="2" class="adz right"> {{item?.adz?.osservazioni.val}} </th>
  </tr>
</table>


<table class="adz" *ngIf="!noval">
  <tr class="adz">
    <th class="adz right">{{+item?.adz?.importo_richiesto.val *100 |myNumber:'.2-2'}}</th>
    <th class="adz right">{{+item?.adz?.importo_concordato.val *1000 |myNumber:'.2-2'}}</th>
    <th class="adz right">{{+item?.adz?.fermo_tecnico.val }} gg</th>
  </tr>
</table>

<!--
<hr>
<a target="_blank" [href]="pdf_perizia" *ngIf="pdf_perizia">Apri il PDF</a>
<hr>-->
<br>