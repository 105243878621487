<div class="card" style="background-color: transparent;">

    <input class="search" type="text" matInput placeholder="Ricerca..." [(ngModel)]="search" (keyup)="onSearch()"
        [ngClass]="{'search-error':onSearchNotFound===true, 'search-ok':onSearchNotFound===false}">

    <button class="search" matSuffix mat-icon-button (click)="search=''; onSearch()" color="dark">
        <mat-icon style="color:gray!important">close</mat-icon>
    </button>

    <div #map class="xmap" style="width:100%; height:90vh;">
    </div>


    <button *ngIf="currentItem" class="select btn-success" mat-raised-button (click)="ok()">SELEZIONA</button>

</div>