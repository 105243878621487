<form [formGroup]="form">
    <div class="card" style="padding:25px; max-height: 80vh; overflow-y:auto; ">

        <br>


        <div class="row">
            <div class="col">
                <mat-form-field class="full">
                    <mat-label>Ruolo</mat-label>
                    <mat-select placeholder="Ruolo" [(ngModel)]="item.ruolo" formControlName="ruolo">
                        <mat-option *ngFor="let i of ruoli" [value]="i">
                            {{i}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


            <div class="col">
        <!--
                <mat-form-field class="full">
                    <mat-label>Invia Email</mat-label>
          
                    <input type="hidden" matInput formControlName="inviaMail" [(ngModel)]="item.inviaMail"
                        style="height: 0;">

                    <mat-slide-toggle [(ngModel)]="item.inviaMail" formControlName="inviaMail"
                        style="position: absolute; top:5px; right:35px">
                    </mat-slide-toggle>
                </mat-form-field>
        -->
            </div>
        </div>


        <div class="row">

            <div class="col">
                <mat-form-field class="full">
                    <mat-label>Nome</mat-label>
                    <input type="text" matInput placeholder="Nome" formControlName="nome" [(ngModel)]="item.nome">
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="full">
                    <mat-label>Cognome</mat-label>
                    <input type="text" matInput placeholder="Cognome" formControlName="cognome"
                        [(ngModel)]="item.cognome">
                </mat-form-field>
            </div>

        </div>

        <div class="row">

            <div class="col">
                <mat-form-field class="full">
                    <mat-label>Email</mat-label>
                    <input type="email" matInput placeholder="Email" formControlName="email" [(ngModel)]="item.email">
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="full">
                    <mat-label>Telefono</mat-label>
                    <input type="text" matInput placeholder="Telefono" formControlName="telefono"
                        [(ngModel)]="item.telefono">
                </mat-form-field>
            </div>

        </div>

        <div class="row">
            <div class="col">
                <button class="btn btn-danger" type="button" mat-raised-button (click)="close()"
                    style="float:right">Annulla</button>
                <button class="btn btn-success" type="button" mat-raised-button (click)="ok()"
                    style="float:right; margin-right:15px">Salva</button>
            </div>
        </div>
    </div>
</form>